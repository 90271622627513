import PerfectScrollbar from 'perfect-scrollbar';
import React, {
  FC, useEffect, useRef, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import { API_BASE_URL } from '../../services/urls';
import {DeleteConfirmation, EditArticleModel, Header, Sidebar} from '../../Components';
import { API } from '../../api';
import { getArticles } from '../../store/actions'

let ps: any;

const EditArticle: FC = () => {
  const [idToDelete, setIdToDelete] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('')

  const [modal, setModal] = useState(false);
  const [articleId, setArticleId] = useState(0);
  const [Articleobj, setArticleobj] = useState(0);

  const articleOptionsName = useSelector(({ articles }: StoreRoot) => articles)

  const dispatch = useDispatch()

  const mainPanel = useRef<any>();
  const id = localStorage.getItem('rest_id') as string

  const showDeleteModal = (id: any) => {
    setIdToDelete(id);
    // '${workinghoursData.find((x) => x.id === id).name}'
    // @ts-ignore
    setDeleteMessage(`Ben je zeker dat je artikel '${articleOptionsName.find((x) => x.id === id).articlename}' wilt verwijderen ?`);

    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  useEffect(() => {
    document.title = 'Bijwerken artikel';
    // @ts-ignore
    document.getElementById('editarticle_colorchange').style.color = 'darkred';
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [modal]);

  useEffect(() => {
    dispatch(getArticles(id))
  }, [id]);

  const toggle = (id: any, articleopt: any) => {
    setModal(!modal);
    setArticleId(id);
    setArticleobj(articleopt);
  }

  const closeModal = (e: any) => {
    e.preventDefault();
    setModal(!modal);
  }

  const articledelete = async (id: string) => {
    const rest_id = localStorage.getItem('rest_id') as string
    await API.restaurantApi.deleteArticles(rest_id, id)
      .then((res) => {
        setDisplayConfirmationModal(false);
        toast.success('Deleted Succesfully!', { containerId: 'B' });
        dispatch(getArticles(rest_id))
      })
      .catch((err) => {
        dispatch(getArticles(rest_id))
      });
  }

  return (
    <>
      <Sidebar />
      <div
        id="border-top-radius"
        className="container-fluid main-panel"
        ref={mainPanel}
      >
        <Header />
        {articleOptionsName && (
          <div className="row mt-5 pt-5">
            <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
              <div className="table-responsive">
                <span className="h4 mb-3"> Beheer artikelen</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button
                  type="button"
                  onClick={() => toggle('new', 'new')}
                  className="btn btn-danger rounded-0 shadow-none"
                >
                  Voeg artikel toe
                </button>
                <table className="table table-striped table-bordered mt-3">
                  <thead>
                    <tr>
                      <th className="small">Categorie</th>
                      <th className="small">Naam artikel</th>
                      <th className="small">Prijs</th>
                      <th className="small">BTW % bij pickup/levering</th>
                      <th className="small">BTW % bij ter plaatse</th>
                      <th className="small">Meer informatie</th>
                      <th className="small">Ingrediënten</th>
                      <th className="small">Afbeelding</th>
                      <th className="small">Actie</th>
                    </tr>
                  </thead>
                  <tbody>
                    {articleOptionsName.map((articleopt: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <td className="small">
                              {articleopt.category ? articleopt.category.name : ''}
                            </td>
                            <td className="small">{articleopt.articlename}</td>
                            <td className="small">{articleopt.price}</td>
                            <td className="small">{articleopt.pickuptax}</td>
                            <td className="small">{articleopt.dineintax}</td>
                            <td className="small">
                              {articleopt.description}
                            </td>
                            <td>
                              {articleopt.ingredient ? articleopt.ingredient.map((ingred: any, index: number) => (
                                <div key={index}>{`${ingred.name},`}</div>
                              )) : ''}
                            </td>
                            <td>
                              {articleopt.image
                              && (
                              <img
                                className="restaurant_images"
                                id="blah"
                                src={API_BASE_URL + articleopt.image}
                                alt=""
                              />
                              )}
                            </td>
                            {/* <td className='small'>{articleopt.ingredient}</td> */}
                            <td className="small">
                              <i
                                className="fa fa-pencil cursr"
                                aria-hidden="true"
                                onClick={() => toggle(articleopt.id, articleopt)}
                              />
                              <i
                                className="fa fa-trash pl-2 cursr"
                                aria-hidden="true"
                                onClick={() => showDeleteModal(articleopt.id)}
                              />
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      <EditArticleModel
        article={Articleobj}
        modal={modal}
        setModal={setModal}
        toggle={closeModal}
        articleId={articleId}
      />
      <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={articledelete} hideModal={hideConfirmationModal} id={idToDelete} message={deleteMessage} />
    </>
  )
}

export default EditArticle;
