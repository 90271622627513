import PerfectScrollbar from 'perfect-scrollbar';
import React, {
  FC, useEffect, useRef, useState,
} from 'react'
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom'
import {
  Header, Sidebar, ArticleOptiongroupModel, Button, DeleteConfirmation,
} from '../../Components';
import { API } from '../../api'

let ps: any;
let optionIndex = 0

const ArticleOptionsFiltered: FC<any> = (props) => {
  const [idToDelete, setIdToDelete] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('')

  const [modal, setModal] = useState(false);
  const [articleOptions, setarticleOptions] = useState([]);
  const [articleoptId, setArticleoptId] = useState(0);
  const [Articleoptobj, setArticleoptobj] = useState(0);
  const [newOptions, setNewOptions] = useState<any>([])
  const [articleAlias, setArticleAlias] = useState('')
  const mainPanel = useRef<any>();
  const rest_id = localStorage.getItem('rest_id') as string;
  const { id } = useParams<{ id: string }>();

  const showDeleteModal = (id: any) => {
    setIdToDelete(id);
    // '${workinghoursData.find((x) => x.id === id).name}'
    // @ts-ignore
    setDeleteMessage(`Ben je zeker dat je keuze '${articleOptions.find((x) => x.id === id).choice}' wilt verwijderen ?`);

    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const getArticleOption = async () => {
    await API.restaurantApi.getArticleOptionForModal(rest_id, id)
      .then((res) => {
        setarticleOptions(res.data);
        console.log('datawithtea', res.data);

        if (res.data && res.data.length > 0) {
          setArticleAlias(res.data[0].seletedalias ? res.data[0].seletedalias.alias : '')
        }
      })
      .catch((err) => console.log('Error is', err.message));
  }

  useEffect(() => {
    document.title = 'Artikel opties';
    // @ts-ignore
    document.getElementById('articleoption_colorchange').style.color = 'darkred';
    getArticleOption()
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [modal, id]);

  const toggle = (id: any, articleopt: any) => {
    setModal(!modal);
    setArticleoptId(id);
    setArticleoptobj(articleopt);
  }

  const deleteoptionnama = async (id: any) => {
    await API.restaurantApi.deleteArticleOption(rest_id, id)
      .then((response) => {
        setDisplayConfirmationModal(false);
        // console.log(response);
        toast.success('Succesvol Verwijderd!', { containerId: 'B' });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        toast.error('Kon niet verwijderen!', { containerId: 'B' });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  }

  const validateForm = () => {
    const x = document.getElementsByName('choice_price')[0] as HTMLInputElement;
    const choice_price = x?.value;
    if (choice_price !== '' && !/^(-?[\d])*(\.([\d])*)?$/.test(choice_price)) {
      toast.error('\'prijs\' bevat geen geldige waarde gebruik enkel een . en cijfers!', { containerId: 'B' });
      return false;
    }
    return true
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    if (!validateForm()) {
      return;
    }
    const rest_id = localStorage.getItem('rest_id') as string

    await API.restaurantApi.addArticleOption({ cats: newOptions }, rest_id, id)
      .then(() => {
        toast.success('Options added', { containerId: 'B' });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        const errorData = err?.response?.data?.data
        if (errorData && typeof errorData === 'object') {
          Object.values<string[]>(errorData).forEach((messages) => {
            toast.error(messages[0], { containerId: 'B' })
          })
        }
      });
  }

  const addNewOption = () => {
    setNewOptions([...newOptions, { choice: '', choice_price: '', id: (optionIndex).toString() }])
    optionIndex++
  }

  const removeOption = (e: any) => {
    try {
      const { id } = e.target.dataset
      if (id) {
        setNewOptions(newOptions.filter((o: any, idx: number) => idx !== Number(id)))
      }
    } catch (error) {
      console.error(error)
    }
  }

  const changeOption = (e: any) => {
    try {
      const { dataset: { id }, value, name } = e.target
      const optionIndex = newOptions.findIndex((o: any) => o.id === id)
      console.log(optionIndex, id, value, name, newOptions)
      if (optionIndex >= 0) {
        const updatedOptions = [...newOptions]
        updatedOptions.splice(optionIndex, 1, { ...updatedOptions[optionIndex], [name]: value })
        setNewOptions(updatedOptions)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleCancel = () => {
    setNewOptions([])
  }


  return (
    <>
      <Sidebar />
      <div
        id="border-top-radius"
        className="container-fluid main-panel"
        ref={mainPanel}
      >
        <Header />
        {articleOptions && (
          <div className="row ml-lg-5 mt-5 pt-5">
            <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
              <span className="h4">Artikel opties</span>
              <div className="table-responsive mt-4">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      {/* <th className='small'>Article ID</th> */}
                      <th className="small">Optie groep</th>
                      <th className="small">Optie</th>
                      <th className="small">Keuze</th>
                      <th className="small">Prijs</th>
                      <th className="small">Actie</th>
                    </tr>
                  </thead>
                  <tbody>
                    {articleOptions.map((articleopt: any, index) => {
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            {/* <td>{articleopt.id}</td> */}
                            <td>{articleopt.optionname.optionname}</td>
                            <td>{articleopt.seletedalias ? articleopt.seletedalias.alias : ''}</td>
                            <td>{articleopt.choice}</td>
                            <td>{articleopt.choice_price}</td>
                            <td>
                              <i
                                className="fa fa-pencil cursr"
                                aria-hidden="true"
                                id={articleopt.id}
                                onClick={() => toggle(articleopt.id, articleopt)}
                              />
                              <i
                                className="fa fa-trash pl-2 cursr"
                                aria-hidden="true"
                                onClick={() => showDeleteModal(articleopt.id)}
                              />
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="row ml-lg-5 mt-2 pt-2">
            <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
              <h5>Voeg keuze toe aan {articleAlias}</h5>
              <button
                type="button"
                style={{ width: '5rem' }}
                className="btn btn-success rounded-0 mt-2 shadow-none"
                onClick={addNewOption}
              >
                +
              </button>
            </div>
          </div>

          {newOptions.map((val: any, idx: number) => {
            const catId = `cat-${idx}`;
            const ageId = `age-${idx}`;
            return (
              <div id={ageId} key={val.id}>
                <div className="row mt-5">
                  <div className="col-md-3" />
                  <div className="col-md-3">
                    <label htmlFor={catId}>
                      {/* <b>{`Cat #${idx + 1}`}</b> */}
                      <b>Keuze</b>
                    </label>
                    <input
                      type="text"
                      name="choice"
                      data-id={val.id}
                      id={catId}
                      value={val.name}
                      onChange={changeOption}
                      className="name form-control"
                      required
                    />

                  </div>
                  <div className="col-md-3">
                    <label htmlFor={ageId}>
                      <b>Prijs</b>
                    </label>
                    <input
                      // type="number"
                      name="choice_price"
                      data-id={val.id}
                      id={ageId}
                      value={val.choice_price}
                      onChange={changeOption}
                      className="age form-control"
                      required
                    />

                    <input
                      type="input"
                      name="id"
                      data-id={val.id}
                      id={ageId}
                      style={{ display: 'none' }}
                      className="age form-control"
                    />

                    <input
                      type="button"
                      name={ageId}
                      data-id={val.id}
                      id={ageId}
                      value="-"
                      className="age float-right mt-2 btn btn-danger w-25 rounded-0 w-25 shadow-none"
                      onClick={removeOption}
                    />

                  </div>
                  <div className="col-md-3" />
                </div>

              </div>
            );
          })}

          {newOptions.length > 0 && (
            <div className="row ml-lg-5 mt-2 pt-2 pb-5">
              <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
                <Button
                  type="submit"
                  name="Opslaan"
                  dangerButton={true}
                  htmlType="submit"
                  btnLongWidth={false}
                />
                <Button
                  name="Annuleren"
                  btnLongWidth={false}
                  onClick={handleCancel}
                />
              </div>
            </div>
          )}
        </form>

      </div>
      <ArticleOptiongroupModel
        articleopt={Articleoptobj}
        modal={modal}
        toggle={toggle}
        articleoptId={articleoptId}
      />
      <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={deleteoptionnama} hideModal={hideConfirmationModal} id={idToDelete} message={deleteMessage} />
    </>
  );
}
export default ArticleOptionsFiltered;
