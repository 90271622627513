import PerfectScrollbar from 'perfect-scrollbar';
import QRCode from 'qrcode.react';
import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  Button, Modal, ModalBody, ModalHeader,
} from 'reactstrap';

import ReactToPrint from 'react-to-print';
import { Header, Sidebar } from '../../Components';
import { API } from '../../api'
import {API_BASE_URL, HAPPYORDER_BASE_URL} from "../../services/urls";

let ps: any;

const encodeParameters = (params: any) => {
  const encodedParams: any = {};
  const encoder = new TextEncoder();

  Object.keys(params).forEach((key) => {
    const number = parseInt(params[key], 10);

    // Pad the number with leading zeros
    const paddedNumber = number.toString().padStart(5, '0');
    const data: any = encoder.encode(paddedNumber);
    encodedParams[key] = window.btoa(String.fromCharCode.apply(null, data));
  });

  return encodedParams;
};

const TablesQRCodes: FC = () => {
  const mainPanel = useRef<any>();
  const [modal, setModal] = useState(false);
  const [tableid, settableId] = useState('');
  const [encodedtableid, setencodedtableId] = useState('');
  const [restaurantData, setRestaurantData] = useState<any>([]);

  const { id } = useParams<any>();

  const QRRef = useRef(null);

  const getRestaurant = async () => {
    await API.restaurantApi.getRestaurant(id)
      .then((res) => {
        setRestaurantData(res.data[0]);
      })
      .catch((err) => console.log('Error is', err.message));
  }

  useEffect(() => {
    document.title = 'QR Download';
    // @ts-ignore
    document.getElementById('restaurant_qrdownload').style.color = 'darkred';
    getRestaurant()

    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [modal, id]);

  const toggle = (e: any) => {
    setModal(!modal);
    settableId(e.target.id);
    const enc = encodeParameters({ table_id: e.target.id })
    setencodedtableId(enc.table_id)
  }

  //   const toggle = () => setModal(!modal);
  const downloadQR = (tableid: string) => {
    const canvas = document.getElementById('123456') as any
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `table_${tableid}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  // const printQR = (tableid: string) => {
  //   const canvas = document.getElementById('123456')?.innerHTML ?? '';
  //   let mywindow = window.open('', 'PRINT', 'height=400,width=600');
  //   mywindow.document.write('<html><head><title>' + 'Tafel: ' + tableid + '</title>');
  //   mywindow.document.write('</head><body>');
  //   mywindow.document.write('<h1>' + 'Tafel: ' + tableid + '</h1>');
  //   mywindow.document.write(canvas);
  //   mywindow.document.write('</body></html>');
  //   mywindow.document.close(); // necessary for IE >= 10
  //   mywindow.focus(); // necessary for IE >= 10*/
  //   mywindow.print();
  //   mywindow.close();
  //
  // };

  const renderQr = (movies: any) => {
    console.log(API_BASE_URL)
    console.log(HAPPYORDER_BASE_URL)
    const items = [];
    for (let i = 1; i <= restaurantData.NumberOfTables; i++) {
      items.push(
        <tr key={i}>
          <td>
            <p className="mt-1">Tafelnummer:{i}</p>{' '}
          </td>
          <td />
          <td />
          <td className="text-center">
            <div className="text-center">
              <Button id={`${i}`} color="danger" onClick={(e: any) => toggle(e)}>
                Maak qr code
              </Button>
            </div>
          </td>
        </tr>,
      );
    }
    return items;
  }

  return (
    <>
      <Sidebar />
      <div
        id="border-top-radius"
        className="container-fluid main-panel"
        ref={mainPanel}
      >
        <Header />
        <div className="row ml-lg-5 mt-5 pt-5" />
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center"><b>QR Codes van tafels</b></div>

            <div className="col-md-2" />
            <div className="col-md-8">
              <table className="table border mt-5">
                <thead>
                  <tr>
                    <th>Tafelnummer</th>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <th /><th />
                    <th className="text-center">Actie</th>
                  </tr>
                </thead>

                <tbody>
                  <>{renderQr(20)}</>
                </tbody>
              </table>
            </div>
            <div className="col-md-2" />
          </div>
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} />
        <ModalBody>
          <div className="text-center">
            <div ref={QRRef} className="PrintSection">
              <h1 className="printTitle">Tafel: {tableid}</h1>
              <QRCode
                id="123456"
                value={`${HAPPYORDER_BASE_URL}/restaurant/${restaurantData.slug}/${encodedtableid}`}
                size={290}
                level="H"
                includeMargin={true}
                className="printQR"
              />
            </div>
            <button type="button" className="cursor btn btn-success ml-1 border-0" onClick={(e) => downloadQR(tableid)}>
              {' '}
              Download QR{' '}
            </button>
            <ReactToPrint
              trigger={() => {
                return <button type="button" className="cursor btn btn-success ml-1 border-0">{' '}Print QR{' '}</button>;
              }}
              content={() => QRRef.current}
            />

          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
export default TablesQRCodes;
