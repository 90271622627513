import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { API } from '../../api';
import { addLoading, removeError, removeLoading } from './index'
import types from '../types'

export default function getCategories(id: string): ThunkAction<Promise<void>, StoreRoot, unknown, AnyAction> {
  return async (dispatch) => {
    const entry = 'getTransactionHistory'

    dispatch(removeError(entry))
    dispatch(addLoading(entry))

    return API.restaurantApi.getCategories(id)
      .then((res) => {
        dispatch({
          type: types.SET_CATEGORIES,
          payload: res.data,
        })
      })
      .catch((err) => console.log('Error is', err.message))
      .finally(() => dispatch(removeLoading(entry)))
  }
}
