import PerfectScrollbar from 'perfect-scrollbar';
import React, {FC, useEffect, useRef, useState,} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import Switch from 'react-switch';
// import { getRestaurantData } from '../../store/actions'
import {format} from 'date-fns';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
// @ts-ignore
import DateFnsLocalizer from 'react-widgets-date-fns';
import nl from 'date-fns/locale/nl';
import {API} from '../../api';
import {API_BASE_URL, HAPPYORDER_BASE_URL} from '../../services/urls'
import {
  Button,
  DeleteConfirmation,
  DownloadQR,
  Header,
  InputField,
  Sidebar,
  VacationDaysModal,
  WorkingHoursModal,
} from '../../Components'
// import DeleteConfirmation from '../../Components/DeleteConfirmation';
import './style.css';

// import DateTimePicker from 'react-widgets/lib/DateTimePicker';

// import DatePicker, { registerLocale } from "react-datepicker";

let ps: any;

const Dashboard: FC = () => {
  const [idToDelete, setIdToDelete] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('')

  const [vacationIdToDelete, setVacationIdToDelete] = useState(null);
  const [vacationDisplayConfirmationModal, setVacationDisplayConfirmationModal] = useState(false);
  const [vacationDeleteMessage, setVacationDeleteMessage] = useState('')
  const [isExceeded, setIsExceeded] = useState(false);
  const [modal, setModal] = useState(false);
  const [vacationmodal, setVacationmodal] = useState(false);
  const [qrmodal, setQrModal] = useState(false);
  const [restaurantData, setRestaurantData] = useState<any>([]);
  const [workinghoursData, setworkinghoursData] = useState([]);
  const [vacationdaysData, setvacationdaysData] = useState([]);
  const [vacationdayId, setvacationdayId] = useState('new');
  const [workinghourId, setworkinghourId] = useState(0);
  const [vacationdayobj, setvacationdayobj] = useState<any>(0);
  const [workinghourobj, setworkinghourobj] = useState<any>(0);
  const { id } = useParams<any>();
  const restid = localStorage.getItem('rest_id') as string;
  const dutchWeekDays = new Map([
    ['Monday', 'Maandag'],
    ['Tuesday', 'Dinsdag'],
    ['Wednesday', 'Woensdag'],
    ['Thursday', 'Donderdag'],
    ['Friday', 'Vrijdag'],
    ['Saturday', 'Zaterdag'],
    ['Sunday', 'Zondag'],
  ]);
  const mainPanel = useRef<any>();

  const restaurant = useSelector(({ restaurantData }: StoreRoot) => restaurantData)

  const dispatch = useDispatch()

  const showDeleteModal = (id: any) => {
    setIdToDelete(id);
    // '${workinghoursData.find((x) => x.id === id).name}'
    // @ts-ignore
    const workinghourToDelete = workinghoursData.find((x) => x.id === id) || { weekday: '', from_hour: '', to_hour: '' }
    setDeleteMessage(`Ben je zeker dat je de openingsuren op '${dutchWeekDays.get(workinghourToDelete.weekday)}' van '${workinghourToDelete.from_hour}' tot '${workinghourToDelete.to_hour}' wilt verwijderen ?`);

    setDisplayConfirmationModal(true);
  };

  const showVacationDeleteModal = (id: any) => {
    setVacationIdToDelete(id);
    // '${workinghoursData.find((x) => x.id === id).name}'
    // @ts-ignore
    const vacationdaysToDelete = vacationdaysData.find((x) => x.id === id) || { vacation_date: '' }
    setVacationDeleteMessage(`Ben je zeker dat je de vakantiedag op '${format(new Date(vacationdaysToDelete.vacation_date), 'dd/MM/yyyy')}' wilt verwijderen ?`);

    setVacationDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const hideVacationConfirmationModal = () => {
    setVacationDisplayConfirmationModal(false);
  };

  const getWorkingHours = () => {
    API.restaurantApi.getRestaurantWorkingHours(restid)
      .then((res) => {
        setworkinghoursData(res.data);
      })
      .catch((err) => console.log('Error is', err.message));
  }

  const getVacationdays = () => {
    API.restaurantApi.getRestaurantVacationDays(restid)
      .then((res) => {
        setvacationdaysData(res.data);
      })
      .catch((err) => console.log('Error is', err.message));
  }

  const getRestaurantData = () => {
    API.restaurantApi.getRestaurant(restid)
      .then((res) => {
        setRestaurantData(res.data[0]);
      })
      .catch((err) => console.log('Error is', err.message));
  }

  useEffect(() => {
    setRestaurantData(restaurant)
  }, [restaurant])

  useEffect(() => {
    getWorkingHours()
    getRestaurantData()
    getVacationdays()
  }, [])

  useEffect(() => {
    // @ts-ignore
    document.getElementById('dashboard_colorchange').style.color = 'darkred';
    // dispatch(getRestaurantData(restid))
    getWorkingHours()
    getRestaurantData()
    getVacationdays()
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [qrmodal, modal, id, restid]);

  const toggle = (id: any, workinghour: any) => {
    setModal(!modal);
    setworkinghourId(id);
    setworkinghourobj(workinghour === 'new' ? {} : workinghour);
    getWorkingHours()
  }

  const vacationToggle = (id: any, vacationday: any) => {
    setVacationmodal(!vacationmodal);
    setvacationdayId(id);
    setvacationdayobj(vacationday);
    getVacationdays()
  }

  const deleteWorkinghour = async (id: any) => {
    await API.restaurantApi.deleteRestaurantWorkingHours(id)
      .then((res) => {
        setDisplayConfirmationModal(false);
        toast.success('Verwijderd!', { containerId: 'B' });
        getWorkingHours()
      });
    setDisplayConfirmationModal(false);
  }

  const deleteVacationday = async (id: any) => {
    await API.restaurantApi.deleteRestaurantVacationDays(id)
      .then((res) => {
        setVacationDisplayConfirmationModal(false);
        toast.success('Verwijderd!', { containerId: 'B' });
        getVacationdays()
      });
    setVacationDisplayConfirmationModal(false);
  }

  const changerestaurantdata = (e: any, field = '') => {
    const newRestaurantData = { ...restaurantData };
    if (e.target === undefined) {
      newRestaurantData[field] = `${e.getHours().toString()}:${e.getMinutes().toString()}:00`;
    } else {
      newRestaurantData[e.target.name] = e.target.value
    }

    const { name, value } = e.target;
    if (name === 'general_message') {  // Check if the input is 'general_message'
      if (value.length <= 400) {
        setIsExceeded(false);
      } else {
        setIsExceeded(true);
      }
    }
    setRestaurantData(newRestaurantData)
  }

  const handleChangeStatus = () => {
    let valueA;
    if (restaurantData.Availability === 'OFFLINE') {
      valueA = 'ONLINE';
    } else {
      valueA = 'OFFLINE';
    }
    setRestaurantData({
      ...restaurantData,
      Availability: valueA,
    });
  }

  const handleChangeDelivery = (e: any) => {
    let valueA;
    if (restaurantData.pickup_option === false) {
      valueA = false;
    } else {
      valueA = true;
    }
    setRestaurantData({
      ...restaurantData,
      delivery_option: e,
    });
  }

  const validateForm = () => {
    let x = document.getElementsByName('availablity_zone_zipcodes')[0] as HTMLInputElement;
    const availablity_zone_zipcodes = x.value;
    if (!/^(\d+\s[a-zA-Z\s-]+,?\s?)*$/.test(availablity_zone_zipcodes)) {
      toast.error('\'Postcodes regio\' bevat niet toegelaten formaat, gebruik zelfde formaat als dit voorbeeld: 9120 Melsele,9100 Sint-Niklaas', { containerId: 'B' });
      return false;
    }

    x = document.getElementsByName('zipcode')[0] as HTMLInputElement;
    const zipcode = x?.value;
    if (!/^([\d])*$/.test(zipcode)) {
      toast.error('\'Postcode\' bevat niet toegelaten symbolen, gebruik enkel cijfers!', { containerId: 'B' });
      return false;
    }

    // x = document.getElementsByName('banner')[0] as HTMLInputElement;
    // const banner = x?.value;
    // if (!/^([\d])*$/.test(banner)) {
    //   toast.error('Banner foto is te groot!', { containerId: 'B' });
    //   return false;
    // }
    //
    // x = document.getElementsByName('logo')[0] as HTMLInputElement;
    // const logo = x?.value;
    // if (!/^([\d])*$/.test(logo)) {
    //   toast.error('Logo foto is te groot!', { containerId: 'B' });
    //   return false;
    // }

    x = document.getElementsByName('service_cost_fixed_amount')[0] as HTMLInputElement;
    const service_cost_fixed_amount = x?.value;
    if (service_cost_fixed_amount !== '' && !/^(-?[\d])*(\.([\d])*)?$/.test(service_cost_fixed_amount)) {
      toast.error('\'Servicekost vast bedrag\' bevat geen geldige waarde gebruik enkel een . en cijfers!', { containerId: 'B' });
      return false;
    }

    x = document.getElementsByName('service_cost_percentage')[0] as HTMLInputElement;
    const service_cost_percentage = x?.value;

    if (service_cost_percentage !== '' && !/^(-?[\d])*(\.([\d])*)?$/.test(service_cost_percentage)) {
      toast.error('\'Servicekost percentage\' bevat geen geldige waarde gebruik enkel een . en cijfers!', { containerId: 'B' });
      return false;
    }

    x = document.getElementsByName('delivery_cost')[0] as HTMLInputElement;
    const delivery_cost = x?.value;
    if (delivery_cost !== '' && !/^(-?[\d])*(\.([\d])*)?$/.test(delivery_cost)) {
      toast.error('\'Leveringskost\' bevat geen geldige waarde gebruik enkel een . en cijfers!', { containerId: 'B' });
      return false;
    }

    x = document.getElementsByName('min_amount_for_free_delivery')[0] as HTMLInputElement;
    const min_amount_for_free_delivery = x?.value;
    if (min_amount_for_free_delivery !== '' && !/^(-?[\d])*(\.([\d])*)?$/.test(min_amount_for_free_delivery)) {
      toast.error('\'Gratis levering (vanaf)\' bevat geen geldige waarde gebruik enkel een . en cijfers!', { containerId: 'B' });
      return false;
    }

    x = document.getElementsByName('min_amount_for_delivery')[0] as HTMLInputElement;
    const min_amount_for_delivery = x?.value;
    if (min_amount_for_delivery !== '' && !/^(-?[\d])*(\.([\d])*)?$/.test(min_amount_for_delivery)) {
      toast.error('\'Levering (vanaf)\' bevat geen geldige waarde gebruik enkel een . en cijfers!', { containerId: 'B' });
      return false;
    }

    return true;
  }

  const SubmitRestaurantdata = async (e: any) => {
    e.preventDefault();

    if (validateForm()) {
      const restname = e.target.elements.RestaurantName.value;
      const location = e.target.elements.Location.value;
      const zipcode = e.target.elements.zipcode.value
      // const restaurantImage = e.target.elements.restaurantImage.value;
      const logoimage = e.target.elements.Logo.files[0];
      const tablesCount = e.target.elements.NumberOfTables.value;
      const onlyAtTable = e.target.elements.OnlyAtTable.value;
      let availibilty;
      const pickup_option = true as any
      const delivery_option = true as any
      const paynowbutton = e.target.elements.paynowbutton.value;
      const paylaterbutton = e.target.elements.paylaterbutton.value;
      const askforbillbutton = e.target.elements.askforbillbutton.value;
      const askforservicebutton = e.target.elements.askforservicebutton.value;
      const apikey = e.target.elements.apikey.value;
      const autoPrint = e.target.elements.autoPrint.value;
      const witteKassa = e.target.elements.witteKassa.value;
      const restaurantImage = e.target.elements.image.files[0];
      // const slug = e.target.elements.slug.value;
      const enable_sms = e.target.elements.enable_sms.value;
      const max_order = e.target.elements.max_order.value
      const time_interval = e.target.elements.time_interval.value
      const order_window = e.target.elements.order_window.value
      const min_order_lead_time = e.target.elements.min_order_lead_time.value
      const daily_order_deadline = e.target.elements.daily_order_deadline.value
      const general_message = e.target.elements.general_message.value
      const delivery_cost = e.target.elements.delivery_cost.value as any
      const min_amount_for_free_delivery = e.target.elements.min_amount_for_free_delivery.value;
      const min_amount_for_delivery = e.target.elements.min_amount_for_delivery.value;
      let availablity_zone_zipcodes
      if (e.target.elements.availablity_zone_zipcodes.value.split(',').pop() === '') {
        availablity_zone_zipcodes = e.target.elements.availablity_zone_zipcodes.value.split(',').slice(0, -1)
      } else {
        availablity_zone_zipcodes = e.target.elements.availablity_zone_zipcodes.value.split(',')
      }
      const service_cost_fixed_amount = e.target.elements.service_cost_fixed_amount.value;
      const service_cost_percentage = e.target.elements.service_cost_percentage.value;
      const service_cost_type = e.target.elements.service_cost_type.value;
      const title_ = e.target.elements.page_title.value
      let meta_titles = []
      if (e.target.elements.meta_titles.value.split(',').pop() === '') {
        meta_titles = e.target.elements.meta_titles.value.split(',').slice(0, -1)
      } else {
        meta_titles = e.target.elements.meta_titles.value.split(',')
      }
      let meta_descriptions = []
      if (e.target.elements.meta_descriptions.value.split(',').pop() === '') {
        meta_descriptions = e.target.elements.meta_descriptions.value.split(',').slice(0, -1)
      } else {
        meta_descriptions = e.target.elements.meta_descriptions.value.split(',')
      }
      // let meta_keywords = []
      // if (e.target.elements.meta_keywords.value.replaceAll(/,/g, ' ').split(' ').pop() === '') {
      //   meta_keywords = e.target.elements.meta_keywords.value.replaceAll(/,/g, ' ').split(' ').slice(0, -1)
      // } else {
      //   meta_keywords = e.target.elements.meta_keywords.value.replaceAll(/,/g, ' ').split(' ')
      // }

      const formData = new FormData();

      formData.append('RestaurantName', restname);

      if (e.target.elements.Availability.checked === true) {
        availibilty = 'ONLINE'
      } else {
        availibilty = 'OFFLINE'
      }

      if (restaurantImage !== undefined) {
        formData.append('image', restaurantImage);
      }
      formData.append('Location', location);
      if (logoimage !== undefined) {
        formData.append('Logo', logoimage);
      }

      formData.append('askforbillbutton', askforbillbutton);
      formData.append('askforservicebutton', askforservicebutton);
      formData.append('enable_sms', enable_sms);
      formData.append('paynowbutton', paynowbutton);
      formData.append('paylaterbutton', paylaterbutton);

      formData.append('NumberOfTables', tablesCount);
      formData.append('OnlyAtTable', onlyAtTable);
      formData.append('Availability', availibilty);
      formData.append('pickup_option', pickup_option);
      formData.append('delivery_option', delivery_option);

      formData.append('apikey', apikey);
      formData.append('autoPrint', autoPrint);
      formData.append('witteKassa', witteKassa);
      // formData.append('slug', slug);
      formData.append('max_order', max_order);
      formData.append('time_interval', time_interval);
      formData.append('order_window', order_window);
      formData.append('min_order_lead_time', min_order_lead_time);
      formData.append('daily_order_deadline', daily_order_deadline);
      formData.append('general_message', general_message);
      formData.append('zipcode', zipcode);

      formData.append('delivery_cost', parseFloat(String(+delivery_cost)).toFixed(2));
      formData.append('min_amount_for_free_delivery', parseFloat(String(+min_amount_for_free_delivery)).toFixed(2));
      formData.append('min_amount_for_delivery', parseFloat(String(+min_amount_for_delivery)).toFixed(2));

      formData.append('availablity_zone_zipcodes', JSON.stringify(availablity_zone_zipcodes));
      formData.append('meta_titles', JSON.stringify(meta_titles));
      // formData.append('meta_keywords', JSON.stringify(meta_keywords));

      formData.append('meta_descriptions', JSON.stringify(meta_descriptions));

      formData.append('service_cost_fixed_amount', service_cost_fixed_amount);
      formData.append('service_cost_percentage', service_cost_percentage);
      formData.append('service_cost_type', service_cost_type);
      formData.append('page_title', title_);

      await API.restaurantApi.updateRestaurantData(formData, restid)
        .then(() => {
          toast.success('Succesvol bewaard!', { containerId: 'B' });
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
        })
        .catch((e) => {
          toast.error(`Bewaren mislukt!\n${e}`, { containerId: 'B' });
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
        });
    }
  }
  DateFnsLocalizer({
    locales: { nl },
  });
  return (
    <>
      <Sidebar />
      <div
        id="border-top-radius"
        className="container-fluid main-panel"
        ref={mainPanel}
      >
        <Header />
        <div className="row mr-md-5 mt-5 pt-5">
          <div className="col-xl-10 offset-xl-1 col-lg-9 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 offsetMd5 text-center">
            <span className="h4 mb-3">Bewerk gegevens</span>
          </div>
          <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-4 col-md-8 offset-md-4 col-sm-12 col-12 mt-3 offsetMd5">
            <form onSubmit={(e) => SubmitRestaurantdata(e)}>
              <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">Naam:</label>
              <InputField
                name="RestaurantName"
                placeholder="Naam"
                value={restaurantData.RestaurantName || ''}
                onChange={(e: any) => changerestaurantdata(e)}
              />
              <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">Locatie:</label>
              <textarea
                className="form-control mt-1"
                rows={6}
                name="Location"
                placeholder="Locatie"
                value={restaurantData.Location}
                onChange={(e) => changerestaurantdata(e)}
              />
              <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">Postcodes & Gemeentes voor leveringen :(kommagescheiden)</label>
              <textarea
                className="form-control mt-1 ints"
                rows={8}
                name="availablity_zone_zipcodes"
                placeholder="vb: 9120 Melsele,9100 Sint-Niklaas"
                value={restaurantData.availablity_zone_zipcodes}
                onChange={(e) => changerestaurantdata(e)}
              />
              <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">Postcode:</label>
              <input
                className="form-control mt-1"
                name="zipcode"
                placeholder="Postcode"
                value={restaurantData.zipcode || ''}
                onChange={(e) => changerestaurantdata(e)}
              />
              <h6 className="mt-3">Banner</h6>
              <div>
                <input
                  id="restimage"
                  name="image"
                  type="file"
                  className="form-control mt-3 p-1"
                  // value={restaurantData.image}
                />
                <h6>Vorige afbeelding</h6>
                {restaurantData.image && (
                <img
                  className="restaurant_images"
                  id="blah"
                  src={API_BASE_URL + restaurantData.image}
                  onChange={(e) => changerestaurantdata(e)}
                  alt=""
                />
                )}
              </div>
              <h6 className="mt-3">Aanpassen Logo</h6>
              <div>
                <input
                  id="logo"
                  name="Logo"
                  type="file"
                  className="form-control mt-3 p-1"
                  onChange={(e) => changerestaurantdata(e)}
                />
                <h6>Vorige afbeelding</h6>
                {restaurantData.Logo && (
                <img
                  id="blah"
                  className="restaurant_images"
                  src={API_BASE_URL + restaurantData.Logo}
                  alt=""
                />
                )}
              </div>
              <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">Algemene boodschap:</label>
              <textarea
                className="form-control mt-1 ints"
                rows={5}
                name="general_message"
                placeholder="Deze text is zichtbaar voor uw klanten als ze uw menu bekijken."
                value={restaurantData.general_message}
                onChange={(e) => changerestaurantdata(e)}
              />
              {isExceeded && (
                <div style={{ color: 'red' }}>
                  Het maximum aantal tekens is 400.
                </div>
              )}
              <div className="form-group">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="control-label font-weight-bold m-0 mb-2 mt-3">
                  Aantal tafels met unieke qr code:
                </label>
                <input
                  name="NumberOfTables"
                  className="form-control"
                  type="number"
                  placeholder="aantal tafels"
                  min="0"
                  step="1"
                  value={restaurantData.NumberOfTables || ''}
                  onChange={(e) => changerestaurantdata(e)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="paynow" className="control-label font-weight-bold m-0 mb-2 mt-3">Alleen bestellen aan tafel:</label>
                <select
                  value={restaurantData.OnlyAtTable}
                  onChange={(e) => changerestaurantdata(e)}
                  name="OnlyAtTable"
                  className="form-control"
                  id="OnlyAtTable"
                >
                  <option value="true">ON</option>
                  <option value="false">OFF</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="availibility" className="control-label font-weight-bold m-0 mb-2 mt-3">Actief:</label>
                <div className="form-group">
                  <Switch onChange={() => handleChangeStatus()} onColor="#dc3545" name="Availability" checked={restaurantData.Availability === 'ONLINE'} />
                </div>
              </div>
              <div className="form-group">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="control-label font-weight-bold m-0 mb-2 mt-3">
                  Mollie online betalingen API:
                </label>
                <input
                  name="apikey"
                  className="form-control"
                  type="input"
                  id="api_id"
                  placeholder="Enter api key"
                  value={restaurantData.apikey || ''}
                  onChange={(e) => changerestaurantdata(e)}
                />
                <div className="form-group">
                  <label htmlFor="paynow" className="control-label font-weight-bold m-0 mb-2 mt-3">Online laten betalen?</label>
                  <select
                    value={restaurantData.paynowbutton}
                    onChange={(e) => changerestaurantdata(e)}
                    name="paynowbutton"
                    className="form-control"
                    id="paynow"
                  >
                    <option value="true">ON</option>
                    <option value="false">OFF</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="paynow" className="control-label font-weight-bold m-0 mb-2 mt-3">Ter plaatse laten betalen?</label>
                  <select
                    value={restaurantData.paylaterbutton}
                    onChange={(e) => changerestaurantdata(e)}
                    name="paylaterbutton"
                    className="form-control"
                    id="paynow"
                  >
                    <option value="true">ON</option>
                    <option value="false">OFF</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="paynow" className="control-label font-weight-bold m-0 mb-2 mt-3">Stuur bestelling automatisch door naar witte kassa:</label>
                  <select
                    value={restaurantData.witteKassa}
                    onChange={(e) => changerestaurantdata(e)}
                    name="witteKassa"
                    className="form-control"
                    id="witteKassa"
                  >
                    <option value="true">ON</option>
                    <option value="false">OFF</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="paynow" className="control-label font-weight-bold m-0 mb-2 mt-3">Nieuwe bestellingen automatisch afdrukken:</label>
                  <select
                    value={restaurantData.autoPrint}
                    onChange={(e) => changerestaurantdata(e)}
                    name="autoPrint"
                    className="form-control"
                    id="autoPrint"
                  >
                    <option value="true">ON</option>
                    <option value="false">OFF</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="paynow" className="control-label font-weight-bold m-0 mb-2 mt-3">Service aanvraag aan tafel</label>
                  <select
                    value={restaurantData.askforservicebutton}
                    onChange={(e) => changerestaurantdata(e)}
                    name="askforservicebutton"
                    className="form-control"
                    id="askforservicebutton"
                  >
                    <option value="true">ON</option>
                    <option value="false">OFF</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="paynow" className="control-label font-weight-bold m-0 mb-2 mt-3">Rekening aanvraag aan tafel</label>
                  <select
                    value={restaurantData.askforbillbutton}
                    onChange={(e) => changerestaurantdata(e)}
                    name="askforbillbutton"
                    className="form-control"
                    id="askforbillbutton"
                  >
                    <option value="true">ON</option>
                    <option value="false">OFF</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="enable_sms" className="control-label font-weight-bold m-0 mb-2 mt-3">Activeer sms functie</label>
                  <select
                    value={restaurantData.enable_sms}
                    onChange={(e) => changerestaurantdata(e)}
                    name="enable_sms"
                    className="form-control"
                    id="enable_sms"
                  >
                    <option value="true">ON</option>
                    <option value="false">OFF</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="service_cost_type" className="control-label font-weight-bold m-0 mb-2 mt-3">Service kost type</label>
                  <select
                    value={restaurantData.service_cost_type}
                    onChange={(e) => changerestaurantdata(e)}
                    name="service_cost_type"
                    className="form-control"
                    id="service_cost_type"
                  >
                    <option value="fixed_amount">Vast</option>
                    <option value="percentage">Percentage</option>
                  </select>
                </div>
                <label htmlFor="service_cost_fixed_amount" className="control-label font-weight-bold m-0 mb-2 mt-3">Servicekost vast bedrag</label>
                <InputField
                  disabled={(restaurantData.service_cost_type && restaurantData.service_cost_type[0] === 'percentage') || restaurantData.service_cost_type === 'percentage'}
                  name="service_cost_fixed_amount"
                  placeholder="prijs"
                  value={restaurantData.service_cost_fixed_amount || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="service_cost_percentage" className="control-label font-weight-bold m-0 mb-2 mt-3">Servicekost percentage</label>
                <InputField
                  disabled={(restaurantData.service_cost_type && restaurantData.service_cost_type[0] === 'fixed_amount') || restaurantData.service_cost_type === 'fixed_amount'}
                  name="service_cost_percentage"
                  placeholder="%"
                  value={restaurantData.service_cost_percentage || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="delveryCost" className="control-label font-weight-bold m-0 mb-2 mt-3">Leveringskost</label>
                <InputField
                  name="delivery_cost"
                  placeholder="prijs"
                  value={restaurantData.delivery_cost || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="fromPriceDelivery" className="control-label font-weight-bold m-0 mb-2 mt-3">Levering (vanaf)</label>
                <InputField
                  name="min_amount_for_delivery"
                  placeholder="prijs"
                  value={restaurantData.min_amount_for_delivery || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="freeDelivery" className="control-label font-weight-bold m-0 mb-2 mt-3">Gratis levering (vanaf)</label>
                <InputField
                  name="min_amount_for_free_delivery"
                  placeholder="prijs"
                  value={restaurantData.min_amount_for_free_delivery || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="slug" className="control-label font-weight-bold m-0 mb-2 mt-3">Titel pagina</label>
                <InputField
                  name="page_title"
                  placeholder="Titel pagina"
                  value={restaurantData.page_title || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">Meta titels(kommagescheiden)</label>
                <textarea
                  className="form-control mt-1"
                  rows={4}
                  name="meta_titles"
                  placeholder="Meta titels "
                  value={restaurantData.meta_titles}
                  onChange={(e) => changerestaurantdata(e)}
                />
                <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">Meta Descriptions(kommagescheiden)</label>
                <textarea
                  className="form-control mt-1"
                  rows={4}
                  name="meta_descriptions"
                  placeholder="Meta Descriptions"
                  value={restaurantData.meta_descriptions}
                  onChange={(e) => changerestaurantdata(e)}
                />
                {/* <label htmlFor="slug" className="control-label font-weight-bold m-0 mb-2 mt-3">Unieke code</label> */}
                {/* <InputField */}
                {/*  required */}
                {/*  name="slug" */}
                {/*  placeholder="Unieke code" */}
                {/*  value={restaurantData.slug || ''} */}
                {/*  onChange={(e: any) => changerestaurantdata(e)} */}
                {/* /> */}
                <label htmlFor="slug" className="control-label font-weight-bold m-0 mb-2 mt-3">Max aantal bestellingen</label>
                <InputField
                  name="max_order"
                  placeholder="Max aantal bestellingen"
                  type="number"
                  min="0"
                  step="1"
                  value={restaurantData.max_order || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="slug" className="control-label font-weight-bold m-0 mb-2 mt-3">Per tijdsinterval (in min)</label>
                <InputField
                  name="time_interval"
                  placeholder="Per tijdsinterval"
                  type="number"
                  min="0"
                  step="1"
                  value={restaurantData.time_interval || 'z'}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="slug" className="control-label font-weight-bold m-0 mb-2 mt-3">Bestelvenster (in dagen)</label>
                <InputField
                  name="order_window"
                  placeholder="Bestelvenster"
                  type="number"
                  min="0"
                  step="1"
                  value={restaurantData.order_window || 0}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="slug" className="control-label font-weight-bold m-0 mb-2 mt-3">Minimale tijd tussen Bestelling en Levering/ophalen (in minuten)</label>
                <InputField
                  name="min_order_lead_time"
                  placeholder="Besteltijdbuffer"
                  type="number"
                  min="0"
                  step="1"
                  value={restaurantData.min_order_lead_time || 0}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="slug" className="control-label font-weight-bold m-0 mb-2 mt-3">Dagelijkse deadline om bestelling te plaatsen voor dezelfde dag</label>
                <DateTimePicker
                  name="daily_order_deadline"
                  format="HH:mm"
                  culture="nl"
                  value={new Date(`January 10, 2019 ${restaurantData.daily_order_deadline}`)}
                  // locale={locale}
                  onChange={(e: any) => changerestaurantdata(e, 'daily_order_deadline')}
                  date={false}
                  step={1}
                  // min={new Date()}
                />
                <label htmlFor="slug" className="control-label font-weight-bold m-0 mb-2 mt-3">URL:</label>
                <a href={`${HAPPYORDER_BASE_URL}/restaurant/${restaurantData.slug}`}>Open jouw URL!</a>
              </div>
              <div className="text-right">
                <Button
                  name="Save"
                  htmlType="submit"
                  dangerButton={true}
                  btnLongWidth={false}
                />
              </div>
            </form>
          </div>
          <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-4 col-md-8 offset-md-4 col-sm-12 col-12 offsetMd5">
            <div className="p-3">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button
                type="button"
                onClick={() => toggle('new', 'new')}
                className="btn btn-success rounded-0 shadow-none"
              >
                + Voeg tijdslot toe
              </button>
              <div className="row border border-secondary mt-2">
                <div className="col-sm-12 text-center mt-2">
                  <h4>Openingsuren</h4>
                </div>
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th className="control-label font-weight-bold m-0 mb-2 mt-3">Dag</th>
                      <th className="control-label font-weight-bold m-0 mb-2 mt-3">Vanaf</th>
                      <th className="control-label font-weight-bold m-0 mb-2 mt-3">Tot</th>
                      <th className="control-label font-weight-bold m-0 mb-2 mt-3">Levering / Afhaal / Alleen met QR</th>
                      <th className="control-label font-weight-bold m-0 mb-2 mt-3">Actie</th>
                    </tr>
                  </thead>
                  <tbody>
                    {workinghoursData && workinghoursData.map((workinghour: any, index) => {
                      return (
                        <tr key={index}>
                          <td>{dutchWeekDays.get(workinghour.weekday)}</td>
                          <td>{workinghour.from_hour}</td>
                          <td>{workinghour.to_hour}</td>
                          <td>
                            <div style={{ display: 'flex' }}>
                              <div style={{ width: '50%' }}> Levering: <b>{workinghour.delivery_option ? 'ON' : 'OFF' }</b></div>
                              <div style={{ width: '50%' }}> Afhaal: <b>{workinghour.pickup_option ? 'ON' : 'OFF' }</b></div>
                              <div style={{ width: '50%' }}> Alleen met QR: <b>{workinghour.only_qr_option ? 'ON' : 'OFF' }</b></div>
                            </div>
                          </td>

                          <td>
                            <i
                              className="fa fa-pencil cursr"
                              aria-hidden="true"
                              id={workinghour.id}
                              onClick={() => toggle(workinghour.id, workinghour)}
                            />
                            <i
                              className="fa fa-trash pl-2 cursr"
                              aria-hidden="true"
                              id={workinghour.id}
                              onClick={() => showDeleteModal(workinghour.id)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-4 col-md-8 offset-md-4 col-sm-12 col-12 offsetMd5">
            <div className="p-3">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button
                type="button"
                onClick={() => vacationToggle('new', 'new')}
                className="btn btn-success rounded-0 shadow-none"
              >
                + Voeg vakantiedag toe
              </button>
              <div className="row border border-secondary mt-2">
                <div className="col-sm-12 text-center mt-2">
                  <h4>Vakantiedagen</h4>
                </div>
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th className="control-label font-weight-bold m-0 mb-2 mt-3">Datum</th>
                      <th className="control-label font-weight-bold m-0 mb-2 mt-3">Actie</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vacationdaysData && vacationdaysData.map((vacationday: any, index) => {
                      // console.log(vacationday)
                      return (
                        <tr key={index}>
                          <td>{format(new Date(vacationday.vacation_date), 'dd/MM/yyyy')}</td>
                          <td>
                            <i
                              className="fa fa-pencil cursr"
                              aria-hidden="true"
                              id={vacationday.id}
                              onClick={() => vacationToggle(vacationday.id, vacationday)}
                            />
                            <i
                              className="fa fa-trash pl-2 cursr"
                              aria-hidden="true"
                              id={vacationday.id}
                              onClick={() => showVacationDeleteModal(vacationday.id)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WorkingHoursModal
        workinghourobj={workinghourobj}
        modal={modal}
        toggle={toggle}
        workinghourid={workinghourId}
      />
      <VacationDaysModal
        vacationdayobj={vacationdayobj}
        modal={vacationmodal}
        toggle={vacationToggle}
        vacationdayid={vacationdayId}
      />
      <DownloadQR />
      <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={deleteWorkinghour} hideModal={hideConfirmationModal} id={idToDelete} message={deleteMessage} />
      <DeleteConfirmation showModal={vacationDisplayConfirmationModal} confirmModal={deleteVacationday} hideModal={hideVacationConfirmationModal} id={vacationIdToDelete} message={vacationDeleteMessage} />
    </>
  );
}
export default Dashboard;
