import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { API_BASE_URL } from '../../services/urls';
import { Header, Sidebar, InputField } from '../../Components';
import { API } from '../../api'
import { showError } from '../../api/errorHundler';
import { useDispatch, useSelector } from 'react-redux';
import { getArticleOptionFilteredForModal } from '../../api/restaurantApi.service';
import { getArticleOptionNames } from '../../store/actions';

const CreateArticleOptions: FC = () => {
  const [cats, setCats] = useState<any>([{
    choice: '', choice_price: '', id: 'age-0',
  }])
  const [alias, setAlias] = useState('')
  const [description, setDescription] = useState('')
  const [min, setMin] = useState('0')
  const [max, setMax] = useState('1')
  const [optiongroup, setOptiongroup] = useState('')
  const [optionsInGroup, setOptionsInGroup] = useState([])
  const params = useParams<any>()
  const articleOptionsNames = useSelector(({ articleOptionNames }: StoreRoot) => articleOptionNames)

  useEffect(() => {
    document.title = 'Product optie';
    // @ts-ignore
    document.getElementById('articleoptiongroup_colorchange').style.color = 'darkred';
  }, [])

  useEffect(() => {
  // declare the data fetching function
    const fetchData = async () => {
      const data = await API.restaurantApi.getArticleOptionFilteredForModal(localStorage.getItem('rest_id') as string, params.id);
      console.log(data.data)
      setOptiongroup(data.data[0].optionName.optionname);
      let temp: any = []
      for (let i = 0; i < data.data.length; i++){
        temp.push(data.data[i].optionalais.alias)
      }
      setOptionsInGroup(temp)
      console.log(temp)
    }
    console.log(params.id)
    // call the function
    fetchData()
    // make sure to catch any error
      .catch(console.error);
  }, [])

  const handleChange = (e: any) => {
    if (['choice', 'choice_price', 'id'].includes(e.target.name)) {
      const _cats = [...cats];
      _cats[e.target.dataset.id][e.target.name] = e.target.value;
      _cats[e.target.dataset.id].id = e.target.id;
      // console.log(`my id${_cats[e.target.dataset.id].id}`);
      setCats(_cats)

    } else {
      // eslint-disable-next-line no-lonely-if
      if (e.target.name === 'alias') {
        setAlias(e.target.value)
      } else if (e.target.name === 'description') {
        setDescription(e.target.value)
      } else if (e.target.name === 'min') {
        setMin(e.target.value)
      } else { setMax(e.target.value) }
    }
  };

  const addCat = (e: any) => {
    const temp = [...cats, { choice: '', choice_price: '', id: `age-${cats.length.toString()}` }];
    setMax(temp ? temp.length.toString() : '0');
    setCats(temp);

  };

  const resetChoiceIDs = (cats: any) => {

    const temp: any = []

    for (let i = 0; i < cats.length; i++){
      let tempcat = cats[i];
      tempcat.id = `age-${i.toString()}`;
      temp.push(tempcat);

    }
    console.log('after del', temp)
    return temp;

  };

  const validateForm = () => {
    const x = document.getElementsByName('choice_price')[0] as HTMLInputElement;
    const choice_price = x?.value;
    if (choice_price !== '' && !/^(-?[\d])*(\.([\d])*)?$/.test(choice_price)) {
      toast.error('\'prijs\' bevat geen geldige waarde gebruik enkel een . en cijfers!', { containerId: 'B' });
      return false;
    }
    return true
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    const url = params.id;
    const rest_id = localStorage.getItem('rest_id') as string;
    const filtered = cats.filter((el: any) => el.choice !== '');
    // @ts-ignore
    if (optionsInGroup.includes(alias)){
      toast.error(`Optie ${alias} bestaat al voor optiegroep ${optiongroup}`, { containerId: 'B' });
    } else if (filtered.length > 0) {
      await API.restaurantApi.createArticleOption({
        cats, alias, description, min, max,
      }, rest_id, url)
        .then(async (res) => {
          const aliasId = res.data;
          const optionnameid = params.id;
          await API.restaurantApi.updateArticleOptionName({
            articleoptionnamerestaurant: rest_id,
            activetab: aliasId,
            optionname: optionnameid,
            restaurant: rest_id,
            optionalais: aliasId,
            optionName: optionnameid,
            min,
            max,
            panel: 'false',
          }, rest_id, url)
            .then((response) => {
              toast.success('Products Option Added  Succesfully!', { containerId: 'B' });
              setTimeout(() => {
                // window.location.reload();
                window.location.href = '/admin/articleoptionsName';
              }, 1000);
            })
            .catch((error) => {
              toast.error('Some Error in Request!', { containerId: 'B' });
            });

          // toast.success('Products Option Added  Succesfully!', { containerId: 'B' });
          setTimeout(() => {
          // window.location.reload();
            window.location.href = '/admin/articleoptionsName';
          }, 2400);
        })
        .catch((err) => showError(err));
    } else {
      toast.error('Products Option Empty is not allowed!', { containerId: 'B' });
    }
  };

  const handleClick = (e: any) => {
    console.log(cats, e.target.id);
    cats.forEach((x: any, i: any) => console.log(x));
    let temp = cats.filter((el: any) => el.id !== e.target.id)
    temp = resetChoiceIDs(temp)
    setCats(temp)
    setMax(temp ? temp.length : '0')

    // @ts-ignore
    // console.log(e.target.id)
    // let toremove = document.getElementById(e.target.id)
    // let parent = document.getElementById("articleoptionform")
    // if (toremove && parent){
    //   console.log('if')
    //   parent.removeChild(toremove)
    // };

  };

  const changeOption = (e: any) => {
    // this.setState({
    //   [e.target.name]: [e.target.value],
    // });
  }

  return (
    <>
      <Sidebar />
      <div
        id="border-top-radius"
        className="container-fluid main-panel"
      >
        <Header />
        <form id="articleoptionform" onSubmit={handleSubmit} onChange={handleChange}>
          <div className="row option_group">
            <div className="col-md-12 mb-5">
              <h3 className="text-center ">Artikel opties aanmaken voor optiegroep: {optiongroup}</h3>
            </div>
            <div className="col-md-3" />
            <div className="col-md-3">
              <label htmlFor="name">
                <b>Optie</b>
              </label>
              <input
                type="text"
                className="form-control"
                name="alias"
                id="alias"
                required
              />
              <button
                type="button"
                className="btn btn-success rounded-0 w-25 float-right mt-2 shadow-none"
                onClick={addCat}
              >
                +
              </button>
            </div>
            <div className="col-md-2" />
            <div className="col-md-3" />
          </div>
          {cats.map((val: any, idx: number) => {
            const catId = `cat-${idx}`;
            const ageId = `age-${idx}`;
            return (
              <div id={ageId} key={idx}>
                <div className="row mt-5">
                  <div className="col-md-3" />
                  <div className="col-md-3">
                    <label htmlFor={catId}>
                      {/* <b>{`Cat #${idx + 1}`}</b> */}
                      <b>Keuze</b>
                    </label>
                    <input
                      type="text"
                      name="choice"
                      data-id={idx}
                      id={catId}
                      value={cats[idx].choice}
                      onChange={(e) => setCats([...cats, { choice: e.target.value }])}
                      className="name form-control"
                      required
                    />

                  </div>
                  <div className="col-md-3">
                    <label htmlFor={ageId}>
                      <b>Prijs</b>
                    </label>
                    <input
                      // type="number"
                      name="choice_price"
                      data-id={idx}
                      id={ageId}
                      value={cats[idx].choice_price}
                      onChange={(e) => setCats([...cats, { choice_price: e.target.value }])}
                      className="age form-control"
                      required
                    />

                    <input
                      type="input"
                      name="id"
                      data-id={idx}
                      id={ageId}
                      style={{ display: 'none' }}
                      className="age form-control"
                    />

                    <input
                      type="button"
                      className="btn btn-success rounded-0 w-25 float-right mt-2 shadow-none"
                      onClick={addCat}
                      value="+"
                    />

                    <input
                      type="button"
                      name={ageId}
                      data-id={idx}
                      id={ageId}
                      value="-"
                      className="age float-right mt-2 mr-3 btn btn-danger w-25 rounded-0 w-25 shadow-none"
                      onClick={handleClick}
                    />

                  </div>
                  <div className="col-md-3" />
                </div>

              </div>
            );
          })}
          <div className="row mt-3">
            <div className="col-md-3" />
            <div className="col-md-3">
              <label htmlFor="name">
                <b>Min keuzes van optie:</b>
              </label>
              <InputField
                type="number"
                className="form-control"
                name="min"
                id="min"
                max={cats ? cats.length : ''}
                min={0}
                value={min}
                onChange={(e: any) => handleChange(e)}
                required
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="description">
                <b>Max keuzes van optie:</b>
              </label>
              <InputField
                type="number"
                className="form-control"
                name="max"
                id="max"
                min={0}
                max={cats ? cats.length : '0'}
                value={max}
                onChange={(e: any) => handleChange(e)}
                required
              />
              <input
                className="float-right btn btn-success mt-3 mb-5"
                type="submit"
                value="Opslaan"
              />
            </div>
            <div className="col-md-3" />
          </div>
        </form>
      </div>
    </>
  );
}
export default CreateArticleOptions;
