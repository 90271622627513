import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { Button, InputField } from './index';
import { API } from '../api'
import { getCategories } from '../store/actions'

const CategoryModal: FC<any> = (props) => {
  const [category, setcategory] = useState<any>('');
  const [categoryid, setcategoryId] = useState<any>(false);
  const restId = localStorage.getItem('rest_id') as string

  const asyncDispatch: ThunkDispatch<StoreRoot, any, AnyAction> = useDispatch()

  useEffect(() => {
    setcategoryId(props.catid);
    if (typeof props.category === 'undefined') {
      setcategory('');
    } else {
      setcategory(props.category.value || '');
    }
  }, [props]);

  const changeOptionName = (e: any) => {
    setcategory(e.target.value);
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const catname = e.target.elements.catname.value;
    const id = localStorage.getItem('rest_id');

    if (props.category === 'new') {
      await API.restaurantApi.createCategory({
        name: catname,
        restaurant: id as string,
      }, id as string)
        .then((response) => {
          toast.success('Added Succesfully!', { containerId: 'B' });
          asyncDispatch(getCategories(restId))
            .then(() => props.toggle())
        })
    } else {
      await API.restaurantApi.updateCategory({
        name: catname,
        restaurant: id as string,
      }, id as string, categoryid as string)
        .then(() => {
          toast.success('Updated Succesfully!', { containerId: 'B' });
          asyncDispatch(getCategories(restId))
            .then(() => props.toggle())
        })
    }
  }

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    props.toggle && props.toggle()
  }

  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>{props.category === 'new' ? 'Voeg category toe' : 'Bewerk categorie'}</ModalHeader>
      <form onSubmit={(e) => handleSubmit(e)}>
        <ModalBody>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group text-left">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="" className="small">
                  Naam
                </label>
                <InputField
                  required
                  name="catname"
                  placeholder="naam"
                  value={category}
                  onChange={(e: any) => changeOptionName(e)}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            name="Annuleren"
            btnLongWidth={false}
            onClick={handleCancel}
            type="button"
          />
          <Button
            name="Opslaan"
            type="submit"
            dangerButton={true}
            btnLongWidth={false}
          // onClick={props.toggle}
          />
        </ModalFooter>
      </form>
    </Modal>
  );
}
export default CategoryModal;
