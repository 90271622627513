import { toast } from 'react-toastify';
import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import {
  InputField, Button, Header, Sidebar,
} from '../../Components';
import { API_BASE_URL } from '../../services/urls';
import { API } from '../../api'

const ChangePassword: FC = () => {
  const mainPanel = useRef<any>();

  useEffect(() => {
    document.title = 'Aanpassen paswoord';
    // @ts-ignore
    document.getElementById('changePassword').style.color = 'darkred';
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const old_password = e.target.elements.old_password.value;
    const new_password = e.target.elements.new_password.value;
    const restid = localStorage.getItem('rest_id') as any
    const formData = new FormData();

    formData.append('old_password', old_password);
    formData.append('new_password', new_password);
    formData.append('restid', restid);

    await API.restaurantApi.changePassword(formData)
      .then((response) => {
        toast.success('Password changed Succesfully!', { containerId: 'B' });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        toast.error('Incorrect old password!', { containerId: 'B' });
      });
  }

  return (
    <>
      <Sidebar />
      <div
        id="border-top-radius"
        className="container-fluid main-panel"
        ref={mainPanel}
      >
        <Header />

        <div className="row mt-5 pt-5">
          <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
            <div className="table-responsive">
              <span className="h4 mb-3">Pas wachtwoord aan</span>

              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="container mt-5">
                  <div className="row align-items-center">

                    <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
                      <InputField type="password" minlength="8" required name="old_password" placeholder="Huidig wachtwoord" />
                    </div>
                    <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
                      <InputField type="password" minlength="8" required name="new_password" placeholder="Nieuw wachtwoord" />
                    </div>
                    <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 text-center mt-3">
                      <Button
                        name="Opslaan"
                        type="submit"
                        dangerButton={true}
                        btnLongWidth={true}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
