import api, { CreateServiceUrl } from './api'

const u = CreateServiceUrl('restaurant')

const login = (data: { username: string; password: string }) => {
  const formData = new FormData()
  formData.set('username', data.username)
  formData.set('password', data.password)

  return api.post(
    u`api/login/`,
    data,
  )
}

const getRestaurant = (id: string) => {
  return api.get(`${u`${id}`}/`)
}

const getRestaurantWorkingHours = (id: string) => {
  const url = `${id}/workinghours`
  return api.get(u`${url}`)
}

const getRestaurantVacationDays = (id: string) => {
  const url = `${id}/vacationdays`
  return api.get(u`${url}`)
}

const deleteRestaurantWorkingHours = (id: string) => {
  const url = `workinghour/delete/${id}`
  return api.get(u`${url}`)
}

const deleteRestaurantVacationDays = (id: string) => {
  const url = `vacationdays/delete/${id}`
  return api.get(u`${url}`)
}

const updateRestaurantData = (data: FormData, id: string) => {
  const url = `restaurantdata/update/${id}`
  return api.post(
    u`${url}`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
}

const createRestaurantWorkingHours = (data: {
  restaurant: string;
  weekday: string;
  to_hour: string;
  from_hour: string;
  pickup_option: string;
  delivery_option: string;
  only_qr_option: string;
}, id: string) => {
  const url = `workinghour/create/${id}`
  return api.post(
    u`${url}`,
    data,
  )
}

const createRestaurantVacationDays = (data: {
  restaurant: string;
  vacation_date: string;
}, id: string) => {
  const url = `vacationdays/create/${id}`
  return api.post(
    u`${url}`,
    data,
  )
}

const updateRestaurantWorkingHours = (data: {
  restaurant: string;
  weekday: string;
  to_hour: string;
  from_hour: string;
  pickup_option: string;
  delivery_option: string;
  only_qr_option: string;
}, id: string) => {
  const url = `workinghour/update/${id}`
  return api.post(
    u`${url}`,
    data,
  )
}

const updateRestaurantVacationDays = (data: {
  restaurant: string;
  vacation_date: string;
}, id: string) => {
  const url = `vacationdays/update/${id}`
  return api.post(
    u`${url}`,
    data,
  )
}

const uploadArticleCSV = (data: FormData, id: string) => {
  const url = `uploadarticlecsv/${id}`
  return api.post(
    u`${url}`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
}

const getArticles = (id: string) => {
  const url = `${id}/getarticles`
  return api.get(u`${url}`)
}

const deleteArticles = (restId: string, id: string) => {
  const url = `${restId}/article/delete/${id}`
  return api.get(u`${url}`)
}

const getCategories = (id: string) => {
  const url = `${id}/categories/`
  return api.get(u`${url}`)
}

const getArticleOptionNameModified = (id: string) => {
  const url = `${id}/articleoptionnamemodified`
  return api.get(u`${url}`)
}

const getPostDataForArticle = (id: string) => {
  const url = `getpostdatagetforarticle/${id}`
  return api.get(u`${url}`)
}

const createArticle = (data: FormData, id: string) => {
  const url = `${id}/article/create`
  return api.post(
    u`${url}`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
}

const updateArticle = (data: FormData, id: string, restId: string) => {
  const url = `${restId}/article/update/${id}`
  return api.post(
    u`${url}`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
}

const deleteCategory = (id: string, restId: string) => {
  const url = `${restId}/category/delete/${id}`
  return api.delete(u`${url}`)
}

const createCategory = (data: {
  restaurant: string;
  name: string;
}, id: string) => {
  const url = `${id}/category/create`
  return api.post(
    u`${url}`,
    data,
  )
}

const updateCategory = (data: {
  restaurant: string;
  name: string;
}, restId: string, id: string) => {
  const url = `${restId}/category/update/${id}`
  return api.post(
    u`${url}`,
    data,
  )
}

const getArticleOptions = (id: string) => {
  const url = `${id}/articleoption`
  return api.get(u`${url}`)
}

const deleteArticleOption = (restId: string, id: string) => {
  const url = `${restId}/articleoption/delete/${id}`
  return api.get(u`${url}`)
}

const getArticleOptionNameSelection = (id: string) => {
  const url = `getarticleOptionNameSelection/${id}`
  return api.get(u`${url}`)
}

const getArticleOptionForModal = (restId: string, id: string) => {
  // console.log('here')
  const url = `${restId}/articleoption/${id}`
  return api.get(u`${url}`)
}

const getArticleOptionFilteredForModal = (restId: string, id: string) => {
  // console.log('here')
  const url = `${restId}/articleoptionname/${id}`
  return api.get(u`${url}`)
}

const getArticleOptionNameMinMax = (id: string) => {
  const url = `getarticleoptionNameMinMax/${id}`
  return api.get(u`${url}`)
}

const getArticleOptionNames = (id: string) => {
  const url = `${id}/articleoptionname`
  return api.get(u`${url}`)
}

const createArticleOptionName = (data: {
  optionname: string;
  articleoptionnamerestaurant: string;
}, id: string) => {
  const url = `${id}/articleoptionname/create`
  return api.post(
    u`${url}`,
    data,
  )
}

const updateArticleOptionName = (data: {
  articleoptionnamerestaurant: string;
  activetab: string;
  optionname: string;
  restaurant: string;
  optionalais: string;
  optionName: string;
  min: string;
  max: string;
  panel: string;
}, restId: string, id: string) => {
  const url = `${restId}/articleoptionname/update/${id}`
  return api.post(
    u`${url}`,
    data,
  )
}

const updateNewArticleOption = (data: {
  optionname: string;
  articleoptionnamerestaurant: string;
}, id: string) => {
  const url = `${id}/articleoption/update`
  return api.post(
    u`${url}`,
    data,
  )
}

const updateArticleOption = (data: {
  articleoptionrestaurant: string;
  optionname: string;
  choice: string;
  choice_price: string;
}, restId: string, id: string) => {
  const url = `${restId}/articleoption/update/${id}`
  return api.post(
    u`${url}`,
    data,
  )
}

const deleteArticleOptionName = (restId: string, id: string) => {
  const url = `${restId}/articleoptionname/delete/${id}`
  return api.get(u`${url}`)
}

const createArticleOption = (data: {
  cats: string;
  alias: string;
  description: string;
  min: string;
  max: string;
}, id: string, _url: string) => {
  const url = `${id}/articleoption/${_url}/create`
  return api.post(
    u`${url}`,
    data,
  )
}

const getAllOrdersList = (id: string, sdate: string, edate: string) => {
  const url = `allorderslist/${id}?from_date=${sdate}&to_date=${edate}`
  return api.get(u`${url}`)
}

const getOrder = (restId: string, id: string) => {
  const url = `${restId}/order/${id}`
  return api.get(u`${url}`)
}

const changePassword = (data: FormData) => {
  return api.post(
    u`change-password/`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
}

const forgetPassword = (data: FormData) => {
  return api.post(
    u`forget-password/`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
}

const confirmPassword = (data: FormData, id: string, code: string) => {
  const url = `${id}/forgot-password-confirm/${code}/`
  return api.post(
    u`${url}`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
}

const resetPassword = (data: FormData, id: string, code: string) => {
  const url = `${id}/forgot-password-reset/${code}/`
  return api.post(
    u`${url}`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
}

const addArticleOption = (data: {
  cats: any;
}, restId: string, id: string) => {
  const url = `${restId}/articleoption/${id}/add`
  return api.post(
    u`${url}`,
    data,
  )
}

export {
  login, getRestaurant, getRestaurantWorkingHours, deleteRestaurantWorkingHours, updateRestaurantData,
  createRestaurantWorkingHours, updateRestaurantWorkingHours, uploadArticleCSV, getArticles, deleteArticles,
  getCategories, getArticleOptionNameModified, getPostDataForArticle, createArticle, updateArticle, deleteCategory,
  createCategory, updateCategory, getArticleOptions, deleteArticleOption, getArticleOptionNameSelection,
  getArticleOptionForModal, getArticleOptionNameMinMax, createArticleOptionName, updateArticleOptionName,
  updateNewArticleOption, updateArticleOption, getArticleOptionNames, deleteArticleOptionName, createArticleOption,
  getAllOrdersList, getOrder, changePassword, forgetPassword, confirmPassword, resetPassword, addArticleOption, getArticleOptionFilteredForModal,
  getRestaurantVacationDays, updateRestaurantVacationDays, createRestaurantVacationDays, deleteRestaurantVacationDays
}
