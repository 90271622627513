import React, { FC, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { API_BASE_URL } from '../services/urls';
import './style.css';
import Button from './Button';
import { API } from '../api'

const OrderModel: FC<any> = (props) => {
  const [Orderdata, setOrderdata] = useState<any>('');
  const id = localStorage.getItem('rest_id') as string;

  const getOrder = async () => {
    await API.restaurantApi.getOrder(id, props.orderId)
      .then((res) => {
        setOrderdata(res.data.Articles);
      })
      .catch((err) => console.log('Error is', err.message));
  }

  useEffect(() => {
    getOrder()
  }, [props]);

  const refresh = (e: any) => {
    e.preventDefault();
    props.setModal(!props.modal);
  }

  return (
    <Modal className="modal-lg" isOpen={props.modal} toggle={(e: any) => props.toggle(e)}>
      {Orderdata
        && (
        <>
          <ModalBody>
            <div className="row">
              {
                Orderdata && Orderdata.map((order: any, index: number) => {
                  console.log(order)
                  // console.log('Order', order);
                  return (
                    <React.Fragment key={index}>
                      <div className="col-md-12 text-center">
                        <span className="h4">Artikel{index + 1}</span>
                      </div>
                      <div className="col-md-6 text-left">
                        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                        <img alt="image" src={API_BASE_URL + order.article.image} height="50px" width="50px" />{'\u00A0'}{order.article_quantity > 1 && (<div>{order.article_quantity} x </div>)}{order.article.articlename}
                      </div>
                      <div className="col-md-6 text-right mt-2">
                        €{order.article.price * order.article_quantity}
                      </div>
                      {/*{*/}
                      {/*  order.article_options.length > 0*/}
                      {/*  && (*/}
                      {/*  <div className="col-md-12 text-center mb-2">*/}
                      {/*    <span className="h6">Opties</span>*/}
                      {/*  </div>*/}
                      {/*  )*/}
                      {/*}*/}

                      <div className="col-md-12">
                        <div className="row">
                          {
                            order.article_options.map((option: any) => {
                              return (
                                <>
                                  <div className="col-md-6 text-right">
                                    {/*{option.article_option.choice}*/}
                                  </div>
                                  <div className="col-md-6 text-right">
                                    {option.article_option.choice}: €{option.choice_price * order.article_quantity}
                                  </div>
                                </>
                              )
                            })
                          }
                        </div>
                      </div>
                      <div className="col-md-6 text-left">
                        Beschrijving:
                      </div>
                      <div className="col-md-6 text-right">
                        {order.article.description}
                      </div>
                    </React.Fragment>
                  )
                })
              }
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              name="Annuleren"
              dangerButton={true}
              btnLongWidth={false}
              onClick={(e: any) => refresh(e)}
            />
          </ModalFooter>
        </>
        )}
    </Modal>
  );
}
export default OrderModel;
