import PerfectScrollbar from 'perfect-scrollbar';
import React, {
  FC, useEffect, useRef, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import {Header, Sidebar, ArticleOptiongroupModel, DeleteConfirmation} from '../../Components';
import { API } from '../../api'
import { getArticleOptions } from '../../store/actions'

let ps: any;
const ArticleOptions: FC = (props) => {
  const [idToDelete, setIdToDelete] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('')

  const [modal, setModal] = useState(false);
  const [articleoptId, setArticleoptId] = useState(0);
  const [Articleoptobj, setArticleoptobj] = useState(0);

  const mainPanel = useRef<any>();
  const id = localStorage.getItem('rest_id') as string

  const articleOptions = useSelector(({ articleOptions }: StoreRoot) => articleOptions)

  const dispatch = useDispatch()

  const showDeleteModal = (id: any) => {
    setIdToDelete(id);
    // '${workinghoursData.find((x) => x.id === id).name}'
    // @ts-ignore
    setDeleteMessage(`Ben je zeker dat je keuze '${articleOptions.find((x) => x.id === id).choice}' wilt verwijderen ?`);

    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  useEffect(() => {
    document.title = 'Artikel opties';
    // @ts-ignore
    document.getElementById('articleoption_colorchange').style.color = 'darkred';
    dispatch(getArticleOptions(id))
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [modal, id]);

  const toggle = (id: any, articleopt: any) => {
    setModal(!modal);
    setArticleoptId(id);
    setArticleoptobj(articleopt);
  }

  const deleteoptionnama = async (id: any) => {
    const restid = localStorage.getItem('rest_id') as string
    await API.restaurantApi.deleteArticleOption(restid as string, id)
      .then((response) => {
        setDisplayConfirmationModal(false);
        toast.success('Deleted Succesfully!', { containerId: 'B' });
        dispatch(getArticleOptions(id))
        window.location.reload()
      })
      .catch((error) => {
        toast.error('Deleted Succesfully!', { containerId: 'B' });
        setTimeout(() => {
          window.location.reload()
        }, 2000);
      })
  }

  return (
    <>
      <Sidebar />
      <div
        id="border-top-radius"
        className="container-fluid main-panel"
        ref={mainPanel}
      >
        <Header />
        {articleOptions && (
          <div className="row ml-lg-5 mt-5 pt-5">
            <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
              <span className="h4">Beheer artikel opties</span>
              <div className="table-responsive mt-4">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      {/* <th className='small'>Article ID</th> */}
                      <th className="small">Optie groep</th>
                      <th className="small">Naam optie</th>
                      <th className="small">Keuze optie</th>
                      <th className="small">Prijs optie</th>
                      <th className="small">Actie</th>
                    </tr>
                  </thead>
                  <tbody>
                    {articleOptions.map((articleopt: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            {/* <td>{articleopt.id}</td> */}
                            <td>{articleopt.optionname.optionname}</td>
                            <td>{articleopt.seletedalias ? articleopt.seletedalias.alias : ''}</td>
                            <td>{articleopt.choice}</td>
                            <td>{articleopt.choice_price}</td>
                            <td>
                              <i
                                className="fa fa-pencil cursr"
                                aria-hidden="true"
                                id={articleopt.id}
                                onClick={() => toggle(articleopt.id, articleopt)}
                              />
                              <i
                                className="fa fa-trash pl-2 cursr"
                                aria-hidden="true"
                                onClick={() => showDeleteModal(articleopt.id)}
                              />
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      <ArticleOptiongroupModel
        articleopt={Articleoptobj}
        modal={modal}
        toggle={toggle}
        articleoptId={articleoptId}
      />
      <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={deleteoptionnama} hideModal={hideConfirmationModal} id={idToDelete} message={deleteMessage} />
    </>
  );
}
export default ArticleOptions;
