import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import React, { FC, useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import './style.css';
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { Button, InputField } from './index';
import { API } from '../api'
import { getCategories, getArticles } from '../store/actions'

let isSold: any = false

const EditArticleModel: FC<any> = (props) => {
  const [articleOption, setArticleOption] = useState<any>('');
  const [articleOptionid, setArticleOptionid] = useState<any>('');
  const [optionname, setoptionname] = useState<any>('');
  const [selectedoptionname, setselectedoptionname] = useState<any>('');
  const [selectedCategory, setselectedCategory] = useState<any>('');
  const [selectedCategoryName, setselectedCategoryName] = useState<any>('');
  const [multipaloptions, setmultipaloptions] = useState<any>([]);
  const [isSoldOut, setSoldOut] = useState<any>(false);
  const [combinedingredients, setcombinedingredients] = useState<any>('');
  const [finalcombinedingredients, setfinalcombinedingredients] = useState('');
  const newArray = [] as any

  const id = localStorage.getItem('rest_id') as string

  const categorylist = useSelector(({ categories }: StoreRoot) => categories)

  const dispatch = useDispatch()

  const asyncDispatch: ThunkDispatch<StoreRoot, any, AnyAction> = useDispatch()

  useEffect(() => {
    try {
      if (props.article) {
        const data = props.article.ingredient;
        const combined = data.map((el: any) => el.name).join(',');
        setcombinedingredients(combined);
        setfinalcombinedingredients(combined);
      }
    } catch (err) {
      setcombinedingredients('');
      setfinalcombinedingredients('');
    }

    dispatch(getCategories(id))
    if (props.article === '' || props.article === 0) {
      setArticleOption([]);
    } else if (props.article === undefined) {
      setArticleOption([]);
    } else {
      setArticleOption(props.article);
      setArticleOptionid(props.articleId);
      const category = 'undefined';
      if (props.articleId === 'new') {
        const category = 'undefined';
      } else if (props.article !== 'undefined') {
        setselectedCategoryName(props.article.category.name);
        // const stateCategory = [...selectedCategory];
        const category = { value: props.article.category.name, label: props.article.category.name };
        // stateCategory.push(category);
        setselectedCategory(category);
        setSoldOut(props.article.sold_out)
      }
    }
  }, [props]);

  const getArticleOptionName = async () => {
    await API.restaurantApi.getArticleOptionNameModified(id as string).then((res) => {
      setoptionname(res.data);
    })
  }

  const getPostDataForArticle = async () => {
    // console.log("hier");
    await API.restaurantApi.getPostDataForArticle(props.articleId as string)
      .then((response) => {
        // console.log("hier2", response);
        if (response.data.length > 0) {
          if (response.data && response.data[0].Articloption) {
            // console.log('just data', response.data);
            response.data.forEach((element: any) => {
              if (element && element.Articloption) {
                const newObj = { value: element.Articloption.label, label: element.Articloption.label };
                newArray.push(newObj);
                // console.log('New Array', newArray);
                // setmultipaloptions(newArray);
              }
            });
            setmultipaloptions(newArray);
          }
        }
      });
  }

  useEffect(() => {
    getArticleOptionName()
    if (props.articleId > 0) {
      setmultipaloptions([]);
      getPostDataForArticle();
    }
  }, [props, props.articleId]);

  const handleChangeCategory = (e: any) => {
    const category = [
      { value: e.value, label: e.value },
    ];
    setselectedCategoryName(e.value);
    setselectedCategory(category);
    // console.log(selectedCategory);
  };

  const changearticledata = (e: any) => {
    setArticleOption({
      ...articleOption,
      [e.target.name]: [e.target.value],
    });
  }

  const changeingredientdata = (e: any) => {
    setcombinedingredients(e.target.value);
    setfinalcombinedingredients(e.target.value);
  }

  const refresh = (e: any) => {
    e.preventDefault();
    props.setModal(!props.modal);
    setmultipaloptions([]);
  }

  const changeOptionName = (e: any) => {
    // const alreadyContainValue = [...multipaloptions];

    if (e === null) {
      setmultipaloptions([]);
    } else {
      setmultipaloptions('')
      setmultipaloptions(e);
    }
    // smultipaloptions
    // alreadyContainValue.push(e);
    setselectedoptionname(e);
    // console.log(selectedoptionname);
  }

  const validateForm = () => {
    const x = document.getElementsByName('price')[0] as HTMLInputElement;
    const price = x?.value;
    if (price !== '' && !/^(-?[\d])*(\.([\d])*)?$/.test(price)) {
      toast.error('\'prijs\' bevat geen geldige waarde gebruik enkel een . en cijfers!', { containerId: 'B' });
      return false;
    }
    return true
  }
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    const articleid = articleOptionid;
    const restid = localStorage.getItem('rest_id') as string;
    const ingredient = finalcombinedingredients;
    const ingredients = e.target.elements.ingredient.value;
    const articlename = e.target.elements.articlename.value;
    const price = e.target.elements.price.value;
    const category = selectedCategoryName;
    const pickuptax = e.target.elements.pickuptax.value;
    const dineintax = e.target.elements.dineintax.value;
    const description = e.target.elements.description.value;
    const image = e.target.elements.image.files[0];
    // console.log('tsrts', isSold)

    const submitoptionname: any = []

    if (multipaloptions.length > 0) {
      multipaloptions.forEach((item: any) => {
        submitoptionname.push(item.value);
      });
    }

    const formData = new FormData();

    formData.append('articleid', articleid);
    formData.append('restid', restid);
    formData.append('articlename', articlename);
    formData.append('price', price);
    formData.append('category', category);
    formData.append('pickuptax', pickuptax);
    formData.append('dineintax', dineintax);
    formData.append('description', description);
    formData.append('ingredient', ingredient);
    formData.append('sold_out', isSoldOut);
    if (submitoptionname.length > 0) {
      formData.append('optionNames', submitoptionname);
    }

    if (image !== undefined) {
      formData.append('image', image);
    }

    if (articleOptionid === 'new') {
      await API.restaurantApi.createArticle(formData, restid)
        .then((response) => {
          toast.success('Added Succesfully!', { containerId: 'B' });
          asyncDispatch(getArticles(id))
            .then(() => props.setModal(!props.modal))
        })
    } else {
      // console.log(formData);
      await API.restaurantApi.updateArticle(formData, articleOptionid, restid)
        .then((response) => {
          toast.success('Updated Succesfully!', { containerId: 'B' });
          asyncDispatch(getArticles(id))
            .then(() => props.setModal(!props.modal))
        })
    }
  }

  isSold = isSoldOut

  return (
    <Modal className="modal-lg" isOpen={props.modal} toggle={(e: any) => props.toggle(e)}>
      {articleOption && (
        <form onSubmit={(e) => handleSubmit(e)}>
          <ModalHeader toggle={(e: any) => refresh(e)}>{articleOptionid === 'new' ? 'Voeg artikel toe' : 'Beheer artikelen'}</ModalHeader>
          <ModalBody>
            <div className="row">
              <h6 className="w-100 text-center">{articleOptionid === 'new' ? 'Toevoegen artikel foto' : 'Update artikel foto'}</h6>
              <div className="w-100 text-center ml-5">
                <input
                  style={{ marginLeft: 30 }}
                  name="image"
                  type="file"
                  onChange={(e) => changearticledata(e)}
                  className="filetype"
                  id="group_image"
                />
              </div>
              <div className="col-sm-4 mt-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>Naam artikel:</b>
                  </label>
                  <InputField
                    name="articlename"
                    value={articleOption ? articleOption.articlename || '' : ''}
                    onChange={(e: any) => changearticledata(e)}
                    placeholder="Enter Article"
                    required
                  />
                </div>
              </div>
              <div className="col-sm-4 mt-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>Prijs:</b>
                  </label>
                  <InputField
                    name="price"
                    value={articleOption ? articleOption.price : ''}
                    onChange={(e: any) => changearticledata(e)}
                    placeholder="Enter Prijs"
                    required
                    // type="number"
                  />
                </div>
              </div>
              <div className="col-sm-4 mt-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>Categorie:</b>
                  </label>
                  <Select
                    name="category"
                    defaultValue={selectedCategory}
                    options={categorylist}
                    value={selectedCategory}
                    onChange={(e) => handleChangeCategory(e)}
                  />
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>BTW % bij pickup/levering:</b>
                  </label>
                  <InputField
                    name="pickuptax"
                    value={articleOption ? articleOption.pickuptax || '' : ''}
                    onChange={(e: any) => changearticledata(e)}
                    placeholder="Tax rate in percentage"
                  />
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b> BTW % bij ter plaatse:</b>
                  </label>
                  <InputField
                    name="dineintax"
                    value={articleOption ? articleOption.dineintax || '' : ''}
                    placeholder="Tax rate in percentage"
                    onChange={(e: any) => changearticledata(e)}
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>Artikel opties:</b>
                  </label>
                  <Select
                    // defaultValue={multipaloptions ? multipaloptions : ''}
                    name="optionnames"
                    options={optionname}
                    value={multipaloptions}
                    onChange={(e) => changeOptionName(e)}
                    isMulti={true}
                    required
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b> Voeg Ingredienten toe (comma seprated):</b>
                  </label>
                  <InputField
                    name="ingredient"
                    id="ingredient"
                    onChange={(e: any) => changeingredientdata(e)}
                    value={articleOption ? combinedingredients : ''}
                  />
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>Artikel is uitverkocht:</b>
                  </label>
                  <div>
                    <input
                      type="radio"
                      name="sold"
                      value="Yes"
                      checked={!!isSoldOut}
                      style={{
                        marginTop: '12px',
                        marginLeft: '0px',
                        marginRight: '17px',
                      }}
                      onChange={
                             (e) => setSoldOut(true)

                           }
                    /><span>Yes</span> {' '}{' '} {' '}
                    <input
                      type="radio"
                      name="sold"
                      value="No"
                      checked={!isSoldOut}
                      style={{
                        marginTop: '12px',
                        marginLeft: '0px',
                        marginRight: '17px',
                      }}
                      onChange={
                             (e) => setSoldOut(false)

                           }
                    /><span>No</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>Meer informatie:</b>
                  </label>
                  <textarea
                    name="description"
                    value={articleOption ? articleOption.description : ''}
                    className="form-control"
                    placeholder="Information"
                    onChange={(e) => changearticledata(e)}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              name="Annuleren"
              btnLongWidth={false}
              type="button"
              onClick={(e: any) => refresh(e)}
            />

            <Button
              name="Opslaan"
              dangerButton={true}
              btnLongWidth={false}
              type="submit"
            />

          </ModalFooter>
        </form>
      )}
    </Modal>
  );
}
export default EditArticleModel;
