import { toast } from 'react-toastify';
import React, { FC, useEffect } from 'react';
import { Logo, InputField, Button } from '../../Components';
import { API_BASE_URL } from '../../services/urls';
import { API } from '../../api'

const ForgotPassword: FC = () => {
  useEffect(() => {
    document.title = 'Paswoord vergeten'
  }, [])

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const email = e.target.elements.email.value;
    const formDataNew = new FormData();
    formDataNew.append('email', email);
    await API.restaurantApi.forgetPassword(formDataNew)
      .then((response) => {
        toast.success('Recovery Email has been sent to your Email Address Succesfully!', { containerId: 'B' });
        setTimeout(() => {
          window.location.href = '/auth/login';
        }, 3000);
      })
      .catch((error) => { toast.error('Email Dosen\'t exist !', { containerId: 'B' }); });
  }

  return (
    <div className="container mt-5">
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="row align-items-center">
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12">
            <Logo
              infoText="Please enter your email address to get new password"
              mutedText={true}
            />
          </div>
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
            <InputField name="email" placeholder="restaurant@web.com" />
          </div>
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 text-center mt-2">
            <Button
              name="Submit"
              dangerButton={true}
              btnLongWidth={false}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default ForgotPassword;
