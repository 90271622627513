import React, { FC, useEffect, useState } from 'react';
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { toast } from 'react-toastify';

// import Moment from 'moment';
// import momentLocaliser from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
//
// import DateFnsLocalizer from 'react-widgets-date-fns';

// import nl from 'date-fns/locale/nl';

// @ts-ignore
import DateFnsLocalizer from 'react-widgets-date-fns';
import nl from 'date-fns/locale/nl';
import { API } from '../api';
import { Button, InputField } from './index';

// import 'react-datepicker/dist/react-datepicker.css'
// import 'react-widgets/dist/css/react-widgets.css';

// Moment.locale('nl');
// momentLocaliser(Moment);

// const DateFnsLocalizerModule = require('react-widgets-date-fns');

import 'react-datepicker/dist/react-datepicker.css'
import 'react-widgets/dist/css/react-widgets.css';

const VacationDaysModal: FC<any> = (props) => {
  const [Vacationday, setVacationday] = useState<any>({ id: '' });

  useEffect(() => {
    if (typeof props.vacationdayobj === 'undefined') {
      setVacationday({ id: '' });
    } else {
      setVacationday(props.vacationdayobj);
    }
    // console.log(Vacationday);
  }, [props]);

  const refresh = () => {
    window.location.reload();
  }

  const changevacationday = (e: any) => {
    // console.log(e)
    setVacationday(e)
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // console.log(e)
    const vacation_date = e.target.elements.vacation_date.value;
    // const to_hour = `${to_hour_temp.getHours().toString()}:${to_hour_temp.getMinutes().toString()}:00`
    // const from_hour = `${from_hour_temp.getHours().toString()}:${from_hour_temp.getMinutes().toString()}:00`
    const id = e.target.elements.id.value;
    const restid = localStorage.getItem('rest_id');

    if (id === '') {
      await API.restaurantApi.createRestaurantVacationDays({
        restaurant: restid as string,
        vacation_date: vacation_date as string,
      }, restid as string)
        .then((response) => {
          toast.success('Added Succesfully!', { containerId: 'B' });
          props.toggle && props.toggle()
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || 'Something wrong', { containerId: 'B' })
        })
    } else {
      await API.restaurantApi.updateRestaurantVacationDays({
        restaurant: restid as string,
        vacation_date: vacation_date as string,
      }, id)
        .then((response) => {
          toast.success('Updated Succesfully!', { containerId: 'B' });
          props.toggle && props.toggle()
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || 'Something wrong', { containerId: 'B' })
          props.toggle && props.toggle()
        })
    }
  };

  // const moment = Moment();
  // momentLocaliser();

  // DateFnsLocalizer({
  //   locales: { "nl": nl },
  // });

  DateFnsLocalizer({
    locales: { nl },
  });

  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>
        {props.vacationdayid === 'new'
          ? 'Voeg vakantiedag toe'
          : 'Edit vakantiedag'}
      </ModalHeader>
      <form onSubmit={(e) => handleSubmit(e)}>
        <ModalBody>
          <div className="form-group text-left">
            <InputField name="id" type="hidden" value={Vacationday.id} />
          </div>

          <div className="form-group text-left">
            <label>
              Choose Date:
              <DateTimePicker
                name="vacation_date"
                format="dd/MM/yyyy"
                culture="nl"
                defaultValue={(props.vacationdayid === 'new') ? undefined : new Date(Vacationday.vacation_date)}
                  // locale={locale}
                onChange={(e: any) => {
                  // locale('nl', nl);
                  changevacationday(e);
                  // disabledTime(value);
                }}
                time={false}
                min={new Date(new Date().setDate(new Date().getDate() + 1))}
              />
            </label>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            name="Annuleren"
            type="reset"
            btnLongWidth={false}
            onClick={refresh}
          />
          <Button
            name="Opslaan"
            type="submit"
            dangerButton={true}
            btnLongWidth={false}
          />
        </ModalFooter>
      </form>
    </Modal>
  );
}

export default VacationDaysModal;
