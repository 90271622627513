import { toast } from 'react-toastify';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Logo, InputField, Button } from '../../Components';
import { API } from '../../api'

const ForgotPasswordReset = () => {
  const [SuccessResponce, setSuccessResponce] = useState(2);
  const { restid } = useParams<any>();
  const { code } = useParams<any>();

  const confirmPassword = async () => {
    const formData = new FormData();
    formData.append('id', restid);
    formData.append('verification_key', code);
    await API.restaurantApi.confirmPassword(formData, restid, code)
      .then((res) => {
        setSuccessResponce(1);
      })
      .catch((err) => {
        setSuccessResponce(0);
      });
  }

  useEffect(() => {
    document.title = 'Paswoord opnieuw instellen';
    confirmPassword()
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const newpassword = e.target.elements.newpassword.value;
    const confirmpassword = e.target.elements.confirmpassword.value;
    if (confirmpassword === newpassword) {
      const formDataNew = new FormData();

      formDataNew.append('id', restid);
      formDataNew.append('password', newpassword);
      formDataNew.append('confirmpassword', confirmpassword);
      formDataNew.append('verification_key', code);
      await API.restaurantApi.resetPassword(formDataNew, restid, code)
        .then((response) => {
          toast.success('Password changed Succesfully!', { containerId: 'B' });
          setTimeout(() => {
            window.location.href = '/auth/login';
          }, 3000);
        })
        .catch((error) => { toast.error('There is some Error!', { containerId: 'B' }); });
    } else {
      toast.error('Your password and confirmation password do not match!', { containerId: 'B' })
    }
  }

  return (
    <>
      {SuccessResponce > 0 && (
        <div className="container mt-5">
          <div className="alert alert-success" role="alert">
            Coupon Applied Successfully.Kindly Enter new Password.
          </div>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="row align-items-center">
              <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
                <Logo infoText="Recover Password" mutedText={false} />
              </div>
              <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
                <InputField type="password" name="newpassword" minlength="8" placeholder="New Password" />
              </div>
              <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
                <InputField type="password" name="confirmpassword" minlength="8" placeholder="Confirm Password" />
              </div>
              <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 text-center mt-3">
                <Button
                  name="Opslaan"
                  dangerButton={true}
                  btnLongWidth={true}
                />
              </div>
            </div>
          </form>
        </div>
      )}
      {SuccessResponce === 0 && (
        <>
          <div className="container mt-5">
            <div className="alert alert-danger" role="alert">
              Password Reset Code is wrong.kindly try again
            </div>
            <Button
              name="GO Back"
              dangerButton={true}
              btnLongWidth={false}
              isLink={true}
              link="/auth/forgotPassword"
            />
          </div>
        </>
      )}
    </>
  );
}

export default ForgotPasswordReset;
