import React, { FC } from 'react'
// import { Modal, Button } from 'reactstrap';
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from 'reactstrap';

interface Props {
  showModal: boolean;
  hideModal: () => void;
  confirmModal: (id: any) => void;
  id: number | null;
  message: string;
}

const DeleteConfirmation: FC<Props> = ({
  showModal,
  hideModal,
  confirmModal,
  id,
  message,
}: any) => {
  return (
      <div className="text-center mt-5">
    <Modal isOpen={showModal} toggle={hideModal}>
      <ModalHeader>
        Bevestig verwijderen
      </ModalHeader>
      <ModalBody>
        <div className="alert alert-danger">{message}</div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={hideModal}>
          Annuleer
        </Button>
        <Button color="danger" onClick={() => confirmModal(id)}>
          Verwijder
        </Button>
      </ModalFooter>
    </Modal>
      </div>
  );
};

export default DeleteConfirmation;
