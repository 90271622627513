export default {
  ADD_LOADING: 'ADD_LOADING',
  REMOVE_LOADING: 'REMOVE_LOADING',
  ADD_ERROR: 'ADD_ERROR',
  REMOVE_ERROR: 'REMOVE_ERROR',
  SET_RESTAURANT_DATA: 'SET_RESTAURANT_DATA',
  SET_ARTICLES: 'SET_ARTICLES',
  SET_CATEGORIES: 'SET_CATEGORIES',
  SET_ARTICLE_OPTIONS: 'SET_ARTICLE_OPTIONS',
  SET_ARTICLE_OPTION_NAMES: 'SET_ARTICLE_OPTION_NAMES',
  RESET_STATE: 'RESET_STATE',
} as const
