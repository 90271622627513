import moment from 'moment'
require('moment/locale/nl')
import DatePicker from 'react-datepicker';
import { CSVLink } from 'react-csv';
import PerfectScrollbar from 'perfect-scrollbar';
import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import { Header, OrderModel, Sidebar } from '../../Components';
import { API } from '../../api'
import 'react-datepicker/dist/react-datepicker.css';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';

// @ts-ignore
import DateFnsLocalizer from 'react-widgets-date-fns';
import nl from 'date-fns/locale/nl';

let ps: any;

function Orders() {
  const [modal, setModal] = useState(false);
  const [OrderData, setOrderData] = useState([]);
  const [orderId, setOrderId] = useState(0);
  const [Articleobj, setArticleobj] = useState(0);
  const [startDate, SetStartDate] = useState<any>(new Date())
  const [endDate, SetendDate] = useState<any>(new Date())
  const headers = [
    { label: 'OrderID', key: 'OrderID' },
    { label: 'Statusbestelling', key: 'Statusbestelling' },
    { label: 'Betalingstatus', key: 'Betalingstatus' },
    { label: 'Tafelnummer', key: 'Tafelnummer' },
    { label: 'OpmerkingKlant', key: 'Opmerkingklant' },
    { label: 'NaamKlant', key: 'NaamKlant' },
    { label: 'E_mail', key: 'E_mail' },
    { label: 'GSM', key: 'GSM' },
    { label: 'Adres', key: 'Adres' },
    { label: 'Bedrag', key: 'Bedrag' },
    { label: 'Wanneer', key: 'Wanneer' }];
  const [data, setCsvData] = useState([])

  const mainPanel = useRef<any>();
  const id = localStorage.getItem('rest_id') as string

  useEffect(() => {
    document.title = 'Bestellingen';
    // @ts-ignore
    document.getElementById('orders_colorchange').style.color = 'darkred';
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [modal]);

  const getAllOrdersList = async (id: string) => {
    const sdate = moment(startDate.getTime()).format('YYYY-MM-DD');
    const edate = moment(endDate.getTime()).format('YYYY-MM-DD');

    await API.restaurantApi.getAllOrdersList(id, sdate, edate)
      .then((res: any) => {
        const temCSVData = res.map((d: any) => ({
          OrderID: `${d.order_id}`,
          DailyOrderNumber: `${d.daily_order_number}`,
          Statusbestelling: String(d.order_status),
          Betalingstatus: String(d.payment_status),
          Tafelnummer: d.tableid === -1 ? '' : `${d.tableid}`,
          OpmerkingKlant: d.user_note,
          NaamKlant: d.customer_name,
          E_mail: d.email,
          GSM: d.phoneNumber,
          Adres: d.address,
          Bedrag: d.total_amount,
          Wanneer: d.delivery_time && d.tableid === -1 ? moment(d.delivery_time.split('+')[0]).locale('nl').format('MMMM, DD YYYY HH:mm') : '',

        }))

        setCsvData(temCSVData)
        setOrderData(res)
      })
      .catch((err) => console.log('Error is', err.message));
  }

  useEffect(() => {
    const rest_id = localStorage.getItem('rest_id') as string

    getAllOrdersList(rest_id)
  }, [id]);

  const getFilterdata = async () => {
    const rest_id = localStorage.getItem('rest_id') as string
    const sdate = moment(startDate.getTime()).format('YYYY-MM-DD');
    const edate = moment(endDate.getTime()).format('YYYY-MM-DD');

    await API.restaurantApi.getAllOrdersList(rest_id, sdate, edate)
      .then((res: any) => {
        console.log('restaurant APP', res)
        const temCSVData = res.map((d: any) => ({
          OrderID: `${d.order_id}`,
          DailyOrderNumber: `${d.daily_order_number}`,
          Statusbestelling: String(d.order_status),
          Betalingstatus: String(d.payment_status),
          Tafelnummer: d.tableid === -1 ? '' : `${d.tableid}`,
          OpmerkingKlant: d.user_note,
          NaamKlant: d.customer_name,
          E_mail: d.email,
          GSM: d.phoneNumber,
          Adres: d.address,
          Bedrag: d.total_amount,
          Wanneer: d.delivery_time && d.tableid === -1 ? moment(d.delivery_time.split('+')[0]).locale('nl').format('MMMM, DD YYYY HH:mm') : '',

        }))
        setCsvData(temCSVData)
        setOrderData(res)
      })
      .catch((err) => console.log('Error is', err.message));
  }

  const toggle = (id: any) => {
    setModal(!modal);
    setOrderId(id);
  }
  const closeModal = (e: any) => {
    e.preventDefault();
    setModal(!modal);
  }
  DateFnsLocalizer({
    locales: { "nl": nl },
  });

  return (
    <>
      <Sidebar />
      <div
        id="border-top-radius"
        className="container-fluid main-panel"
        ref={mainPanel}
      >
        <Header />
        <div className="row mt-5 pt-5">
          <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
            <div className="table-responsive" style={{ minHeight: '60vh' }}>
              <span className="h4 mb-3"> Overzicht bestellingen</span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div style={{ display: 'flex' }}>
                <form style={{ display: 'flex' }} onSubmit={(e) => { e.preventDefault();getFilterdata() }}>
                  <div>
                    <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3" style={{ padding: '2px 10px 0px' }}>Start Datum</label>
                    {/*<DatePicker*/}
                    {/*  selected={startDate}*/}
                    {/*  onChange={(date) => SetStartDate(date)}*/}
                    {/*  required*/}
                    {/*/>*/}
                    <DateTimePicker
                      defaultValue={startDate}
                      onChange={(date) => SetStartDate(date)}
                      format="dd/MM/yyyy"
                      culture="nl"
                      time={false}
                    />
                  </div>
                  <div>
                    <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3" style={{ padding: '2px 10px 0px' }}>Eind Datum</label>
                    {/*<DatePicker*/}
                    {/*  selected={endDate}*/}
                    {/*  onChange={(date) => SetendDate(date)}*/}
                    {/*  required*/}
                    {/*/>*/}
                    <DateTimePicker
                      defaultValue={endDate}
                      onChange={(date) => SetendDate(date)}
                      format="dd/MM/yyyy"
                      culture="nl"
                      time={false}
                    />
                  </div>
                  <div style={{ padding: '0px 20px 8px' }}>
                    <button
                      type="submit"
                      className="btn btn-success  shadow-none"
                      style={{
                        padding: '3px 33px 3px',
                        marginTop: '12px',
                        borderRadius: '7px',
                      }}
                    >
                      Filter
                    </button>
                  </div>
                </form>

                <div style={{ marginTop: '11px' }} className="csv-data">
                  <CSVLink
                    data={data}
                    headers={headers}
                    target="_blank"
                    className="btn btn-primary btn-success btn-sm"
                    filename={`orderdata_${new Date()}.csv`}
                  >
                    Download CSV
                  </CSVLink>
                  {/* </button> */}
                </div>
              </div>
              <table className="table table-striped table-bordered mt-3">
                <thead>
                  <tr>
                    <th className="small">Order ID</th>
                    <th className="small">Status bestelling</th>
                    <th className="small">Status betaling</th>
                    <th className="small">Tafelnummer</th>
                    <th className="small">Opmerking klant</th>
                    <th className="small">Naam klant</th>
                    <th className="small">E-mail</th>
                    <th className="small">GSM</th>
                    <th className="small">Adres</th>
                    <th className="small">Bedrag</th>
                    <th className="small">Wanneer </th>
                    <th className="small">Bestelling</th>
                  </tr>
                </thead>
                <tbody>
                  {OrderData && OrderData.map((order: any, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td className="small">{order.daily_order_number}</td>
                          <td className="small">{order.order_status === 'Pending' ? 'in wacht' : (order.order_status === 'Accepted' ? 'geaccepteerd' : 'geannuleerd')}</td>
                          <td className="small">{order.payment_status ? 'betaald' : 'niet betaald'}</td>
                          <td className="small">{order.tableid === -1 ? '' : order.tableid }</td>
                          <td className="small">{order.user_note ? order.user_note : '' }</td>
                          <td className="small">{order.customer_name ? order.customer_name : '' }</td>
                          <td className="small">{order.email ? order.email : '' }</td>
                          <td className="small">{order.phoneNumber ? order.phoneNumber : '' }</td>
                          <td className="small">{order.address ? order.address : '' }</td>
                          <td className="small">
                            {order.total_amount}
                          </td>
                          <td className="small">
                            {order.delivery_time ? `${moment(order.delivery_time.split('+')[0]).locale('nl').format('DD MMMM YYYY HH:mm')}` : ''}
                          </td>
                          <td className="small">
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                              onClick={() => toggle(order.order_id)}
                            />
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <OrderModel
        modal={modal}
        setModal={setModal}
        toggle={closeModal}
        orderId={orderId}
      />
    </>
  );
}

export default Orders;
