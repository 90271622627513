import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import './style.css';

const Button: FC<any> = (props) => {
  const dangerButtonClass = props.dangerButton ? ' btn-danger  ' : '';
  const btnLongWidthClass = props.btnLongWidth ? ' btn-long-width ' : '';

  if (props.isLink) {
    return (
      <Link to={props.link}>
        <button
          // eslint-disable-next-line react/button-has-type
          type={props.type}
          className={`btn ${dangerButtonClass + btnLongWidthClass}`}
          disabled={props.disabled}
        >
          {props.name}
        </button>
      </Link>
    );
  }
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={props.type}
      className={`btn ${dangerButtonClass + btnLongWidthClass}`}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.name}
    </button>
  );
}

export default Button;
