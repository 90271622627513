import { toast } from 'react-toastify';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API } from '../../api';
import { Logo, InputField, Button } from '../../Components';

const Login: FC = () => {
  useEffect(() => {
    document.title = 'Login'
  }, [])

  const handleFormSubmission = async (event: any) => {
    event.preventDefault();
    const username = event.target.elements.username.value;
    const password = event.target.elements.password.value;
    await API.restaurantApi.login({ username, password })
      .then((response) => {
        console.log(response.data.data.token);
        window.localStorage.setItem('jwt', response.data.data.token);
        window.localStorage.setItem(
          'rest_id',
          response.data.data.restaurant.id,
        );
        if (response.data.data) { // @ts-ignore
          window.location = `/admin/dashboard/${response.data.data.restaurant.id}`;
        }
      })
      .catch((error) => {
        toast.error('Email or Password is wrong', { containerId: 'B' });
      });
  };

  return (
    <form onSubmit={(event) => handleFormSubmission(event)}>
      <div className="container mt-5">
        <div className="row align-items-center">
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12">
            <Logo infoText="Log in" mutedText={false} />
          </div>
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
            <InputField type="email" name="username" placeholder="E-mail" />
          </div>
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
            <InputField
              type="password"
              name="password"
              placeholder="Wachtwoord"
            />
          </div>
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mb-2 text-right">
            <Link to="/auth/forgotPassword">
              <span className="text-muted small">Forgot Password?</span>
            </Link>
          </div>
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 text-center mt-2">
            <Button
              htmlType="submit"
              name="Log in"
              dangerButton={true}
              btnLongWidth={true}
              Link={true}
            />
          </div>
        </div>
      </div>
    </form>
  );
}
export default Login;
