import React, { FC, useEffect, useState } from 'react';
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { toast } from 'react-toastify';

// import Moment from 'moment';
// import momentLocaliser from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
//
// import DateFnsLocalizer from 'react-widgets-date-fns';

// import nl from 'date-fns/locale/nl';

// @ts-ignore
import DateFnsLocalizer from 'react-widgets-date-fns';
import nl from 'date-fns/locale/nl';
import { API } from '../api';
import { Button, InputField } from './index';

// import 'react-datepicker/dist/react-datepicker.css'
// import 'react-widgets/dist/css/react-widgets.css';

// Moment.locale('nl');
// momentLocaliser(Moment);

// const DateFnsLocalizerModule = require('react-widgets-date-fns');

import 'react-datepicker/dist/react-datepicker.css'
import 'react-widgets/dist/css/react-widgets.css';

const WorkingHoursModal: FC<any> = (props) => {
  const [Workinghour, setWorkinghour] = useState<any>('');

  useEffect(() => {
    if (typeof props.workinghourobj === 'undefined') {
      setWorkinghour({});
    } else {
      setWorkinghour(props.workinghourobj);
    }
    // console.log(Workinghour)
  }, [props]);

  const refresh = () => {
    window.location.reload();
  }

  const changeworkinghour = (e: any, field = '') => {
    const newWorkinghour = { ...Workinghour };
    if (e.target === undefined) {
      const time = `${e.getHours().toString()}:${e.getMinutes().toString()}:00`
      // console.log(time)
      newWorkinghour[field] = time;
    } else {
      newWorkinghour[e.target.name] = e.target.value
      console.log(e.target.name, e.target.value)
      if (e.target.name === 'only_qr_option' && e.target.value === 'true') {
        newWorkinghour.delivery_option = 'false'
        newWorkinghour.pickup_option = 'false'
      }
      if (e.target.name === 'only_qr_option' && e.target.value === 'false') {
        newWorkinghour.delivery_option = 'true'
        newWorkinghour.pickup_option = 'true'
      }
      else if (e.target.name !== 'only_qr_option' && e.target.value === 'true') {
        newWorkinghour.only_qr_option = 'false'
      }
      else if (e.target.name !== 'only_qr_option' && newWorkinghour.delivery_option === 'false' && newWorkinghour.pickup_option === 'false') {
        newWorkinghour.only_qr_option = 'true'
      }
    }
    setWorkinghour(newWorkinghour)
  }


  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // console.log(e.target.elements)
    const weekday = e.target.elements.weekday.value;
    const to_hour = e.target.elements.to_hour.value;
    const from_hour = e.target.elements.from_hour.value;
    // const to_hour = `${to_hour_temp.getHours().toString()}:${to_hour_temp.getMinutes().toString()}:00`
    // const from_hour = `${from_hour_temp.getHours().toString()}:${from_hour_temp.getMinutes().toString()}:00`
    const id = e.target.elements.id.value;
    const pickoption = e.target.elements.pickup_option.value;
    const deliveryoption = e.target.elements.delivery_option.value;
    const onlyqroption = e.target.elements.only_qr_option.value

    if (pickoption === 'false' && deliveryoption === 'false' && onlyqroption === 'false') {
      toast.error('Levering/Afhaal/Alleen QR kunnen niet allemaal op status OFF staan !', { containerId: 'B' });

      return 0;
    }


    if (to_hour < from_hour) {
      toast.error('\'Tot\' tijdstip kan niet kleiner zijn dan \'Vanaf\'! Als u na middernacht wilt open blijven kan u een nieuw tijdslot toevoegen voor de volgende dag.', { containerId: 'B' });
      return 0;
    }

    const restid = localStorage.getItem('rest_id');

    if (id === '') {
      await API.restaurantApi.createRestaurantWorkingHours({
        restaurant: restid as string,
        weekday,
        to_hour,
        from_hour,
        pickup_option: pickoption,
        delivery_option: deliveryoption,
        only_qr_option: onlyqroption,
      }, restid as string)
        .then((response) => {
          toast.success('Added Succesfully!', { containerId: 'B' });
          props.toggle && props.toggle()
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || 'Something wrong', { containerId: 'B' })
        })
    } else {
      await API.restaurantApi.updateRestaurantWorkingHours({
        restaurant: restid as string,
        weekday,
        to_hour,
        from_hour,
        pickup_option: pickoption,
        delivery_option: deliveryoption,
        only_qr_option: onlyqroption,
      }, id)
        .then((response) => {
          toast.success('Updated Succesfully!', { containerId: 'B' });
          props.toggle && props.toggle()
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || 'Something wrong', { containerId: 'B' })
          props.toggle && props.toggle()
        })
    }
  };

  // const moment = Moment();
  // momentLocaliser();

  // DateFnsLocalizer({
  //   locales: { "nl": nl },
  // });

  DateFnsLocalizer({
    locales: { nl },
  });

  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>
        {props.workinghourid === 'new'
          ? 'Voeg tijdslot toe'
          : 'Edit tijdslot'}
      </ModalHeader>
      <form onSubmit={(e) => handleSubmit(e)}>
        <ModalBody>
          <div className="row">
            <div className="form-group text-left">
              <InputField name="id" type="hidden" value={Workinghour.id} />
            </div>
            <div className="col-sm-12">
              <div className="form-group text-left">
                <label>
                  Kies dag van de week:
                  <select
                    className="form-control mt-2"
                    name="weekday"
                    value={Workinghour.weekday}
                    onChange={(e) => changeworkinghour(e)}
                  >
                    <option value="Monday">Maandag</option>
                    <option value="Tuesday">Dinsdag</option>
                    <option value="Wednesday">Woensdag</option>
                    <option value="Thursday">Donderdag</option>
                    <option value="Friday">Vrijdag</option>
                    <option value="Saturday">Zaterdag</option>
                    <option value="Sunday">Zondag</option>
                  </select>
                </label>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group text-left">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="" className="small">
                  Vanaf
                </label>
                {/* <InputField */}
                {/*  required */}
                {/*  name="from_hour" */}
                {/*  placeholder="1:00:00" */}
                {/*  value={Workinghour.from_hour} */}
                {/*  onChange={(e: any) => changeworkinghour(e)} */}
                {/* /> */}
                <DateTimePicker
                  name="from_hour"
                  format="HH:mm"
                  culture="nl"
                  defaultValue={props.workinghourid === 'new' ? undefined : new Date(`January 10, 2019 ${Workinghour.from_hour}`)}
                  // locale={locale}
                  onChange={(e: any) => {
                    // locale('nl', nl);

                    changeworkinghour(e, 'from_hour');
                    // disabledTime(value);
                  }}
                  date={false}
                  step={1}
                  // min={new Date()}
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group text-left">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="" className="small">
                  Tot
                </label>
                {/* <InputField */}
                {/*  required */}
                {/*  placeholder="9:00:00" */}
                {/*  name="to_hour" */}
                {/*  value={Workinghour.to_hour} */}
                {/*  onChange={(e: any) => changeworkinghour(e)} */}
                {/* /> */}
                <DateTimePicker
                  name="to_hour"
                  format="HH:mm"
                  culture="nl"
                  defaultValue={props.workinghourid === 'new' ? undefined : new Date(`January 10, 2019 ${Workinghour.to_hour}`)}
                  // locale={locale}
                  onChange={(e: any) => {
                    // locale('nl', nl);

                    changeworkinghour(e, 'to_hour');
                    // disabledTime(value);
                  }}
                  date={false}
                  // min={new Date(2000, 0, 1, 0, 0)} // Set the minimum time to 00:00
                  // max={new Date(2000, 0, 1, 23, 59)} // Set the maximum time to 23:59
                  step={1} // Set the step size to 30 minutes
                  // min={new Date()}
                />
              </div>
            </div>
            <div className="form-group col-sm-12">
              <label htmlFor="delivery" className="control-label font-weight-bold m-0 mb-2 mt-3">Levering optie:</label>
              <select
                value={Workinghour.delivery_option}
                onChange={(e) => {
                  changeworkinghour(e);
                }}
                name="delivery_option"
                className="form-control"
                id="delivery"
              >
                <option value="true">ON</option>
                <option value="false">OFF</option>
              </select>
            </div>
            <div className="form-group col-sm-12">
              <label htmlFor="pickoption" className="control-label font-weight-bold m-0 mb-2 mt-3">Afhaal optie:</label>
              <select
                value={Workinghour.pickup_option}
                onChange={(e) => {
                  changeworkinghour(e);
                }}
                name="pickup_option"
                className="form-control"
                id="pickoption"
              >
                <option value="true">ON</option>
                <option value="false">OFF</option>
              </select>
            </div>
            <div className="form-group col-sm-12">
              <label htmlFor="onlyqroption" className="control-label font-weight-bold m-0 mb-2 mt-3">Alleen met QR optie:</label>
              <select
                value={Workinghour.only_qr_option? Workinghour.only_qr_option : 'false'}
                onChange={(e) => {
                  changeworkinghour(e);
                }}
                name="only_qr_option"
                className="form-control"
                id="onlyqroption"
              >
                <option value="true">ON</option>
                <option value="false">OFF</option>
              </select>
            </div>

          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            name="Annuleren"
            type="reset"
            btnLongWidth={false}
            onClick={refresh}
          />
          <Button
            name="Opslaan"
            type="submit"
            dangerButton={true}
            btnLongWidth={false}
          />
        </ModalFooter>
      </form>
    </Modal>
  );
}

export default WorkingHoursModal;
